
import { Options, Vue } from "vue-class-component";
import DotsIcon from "@/assets/icons/DotsIcon.vue";

@Options({
  components: {
    DotsIcon,
  },
  props: {
    text: {
      type: String,
    },
    customClass: String,
  },
})
export default class SectionTitle extends Vue {}
