import { Questionnaire } from "@/shared/types/Questionnaire";
import { $t } from "@/utils/helpers";

function checkNumber(num: number) {
  if (num >= 10) {
    return 3;
  } else if (num >= 6) {
    return 2;
  } else {
    return 0;
  }
}

export const Questionnaires: Questionnaire[] = [
  {
    language: "sk",
    sections: [
      {
        id: 1,
        order: 1,
        category: "A",
        title: $t("questionnaire.section-1.title"),
        perex: $t("questionnaire.section-1.perex"),
        description1: $t("questionnaire.section-1.description1"),
        questions: [
          {
            id: 1,
            order: 1,
            notRequired: true,
            question: $t("questionnaire.a1.title"),
            slug: "a1",
            multiple: true,
            answers: [
              {
                name: $t("questionnaire.a1.option1"),
                value: "bonds",
                points: 1,
              },
              {
                name: $t("questionnaire.a1.option2"),
                value: "options",
                points: 3,
              },
              {
                name: $t("questionnaire.a1.option3"),
                value: "securities",
                points: 1,
              },
              {
                name: $t("questionnaire.a1.option4"),
                value: "shares",
                points: 2,
              },
              {
                name: $t("questionnaire.a1.option5"),
                value: "none",
                points: 0,
              },
            ],
          },
          {
            id: 2,
            order: 2,
            notRequired: true,
            question: $t("questionnaire.a2.title"),
            slug: "a2",
            multiple: true,
            answers: [
              {
                name: $t("questionnaire.a2.option1"),
                value: "instruction",
                points: 1,
              },
              {
                name: $t("questionnaire.a2.option2"),
                value: "portfolio_management",
                points: 1,
              },
              {
                name: $t("questionnaire.a2.option3"),
                value: "consulting",
                points: 1,
              },
              {
                name: $t("questionnaire.a2.option4"),
                value: "none",
                points: 0,
              },
            ],
          },
          {
            id: 3,
            order: 3,
            question: $t("questionnaire.a3.title"),
            slug: "a3",
            answers: [
              {
                name: $t("questionnaire.a3.option1"),
                value: "none",
                points: 0,
              },
              {
                name: $t("questionnaire.a3.option2"),
                value: "less_then_year",
                points: 1,
              },
              {
                name: $t("questionnaire.a3.option3"),
                value: "more_then_year",
                points: 2,
              },
              {
                name: $t("questionnaire.a3.option4"),
                value: "more_then_5_years",
                points: 3,
              },
            ],
          },
          {
            id: 4,
            order: 4,
            question: $t("questionnaire.a4.title"),
            slug: "a4",
            answers: [
              {
                name: $t("questionnaire.a3.option1"),
                value: "none",
                points: 0,
              },
              {
                name: $t("questionnaire.a3.option2"),
                value: "less_then_year",
                points: 2,
              },
              {
                name: $t("questionnaire.a3.option3"),
                value: "more_then_year",
                points: 3,
              },
              {
                name: $t("questionnaire.a3.option4"),
                value: "more_then_5_years",
                points: 5,
              },
            ],
          },
          {
            id: 5,
            order: 5,
            question: $t("questionnaire.a5.title"),
            slug: "a5",
            answers: [
              {
                name: $t("questionnaire.a3.option1"),
                value: "none",
                points: 0,
              },
              {
                name: $t("questionnaire.a3.option2"),
                value: "less_then_year",
                points: 1,
              },
              {
                name: $t("questionnaire.a3.option3"),
                value: "more_then_year",
                points: 2,
              },
              {
                name: $t("questionnaire.a3.option4"),
                value: "more_then_5_years",
                points: 3,
              },
            ],
          },
          {
            id: 6,
            order: 6,
            question: $t("questionnaire.a6.title"),
            slug: "a6",
            answers: [
              {
                name: $t("questionnaire.a3.option1"),
                value: "none",
                points: 0,
              },
              {
                name: $t("questionnaire.a3.option2"),
                value: "less_then_year",
                points: 2,
              },
              {
                name: $t("questionnaire.a3.option3"),
                value: "more_then_year",
                points: 3,
              },
              {
                name: $t("questionnaire.a3.option4"),
                value: "more_then_5_years",
                points: 4,
              },
            ],
          },
          {
            id: 7,
            order: 7,
            question: $t("questionnaire.a7.title"),
            slug: "a7",
            multiple: true,
            answers: [
              {
                name: $t("questionnaire.a7.option1"),
                value: "regular",
                points: 2,
              },
              {
                name: $t("questionnaire.a7.option2"),
                value: "one_time",
                points: 1,
              },
              {
                name: $t("questionnaire.a7.option3"),
                value: "occasional",
                points: 1,
              },
              {
                name: $t("questionnaire.a7.option4"),
                value: "speculative",
                points: 7,
              },
              {
                name: $t("questionnaire.a7.option5"),
                value: "none",
                points: 0,
              },
            ],
          },
          {
            id: 8,
            order: 8,
            question: $t("questionnaire.a8.title"),
            slug: "a8",
            answers: [
              {
                name: $t("questionnaire.a8.option1"),
                value: "none",
                points: 0,
              },
              {
                name: $t("questionnaire.a8.option2"),
                value: "internet",
                points: 3,
              },
              {
                name: $t("questionnaire.a8.option3"),
                value: "job",
                points: 7,
              },
            ],
          },
          {
            id: 9,
            order: 9,
            question: $t("questionnaire.a9.title"),
            slug: "a9",
            multiple: true,
            answers: [
              {
                name: $t("questionnaire.a9.option1"),
                value: "shares",
                points: 3,
              },
              {
                name: $t("questionnaire.a9.option2"),
                value: "bonds",
                points: 2,
              },
              {
                name: $t("questionnaire.a9.option3"),
                value: "funds",
                points: 2,
              },
              {
                name: $t("questionnaire.a9.option4"),
                value: "other",
                points: 2,
                hasInput: true,
              },
              {
                name: $t("questionnaire.a9.option5"),
                value: "none",
                points: 0,
              },
            ],
          },
          {
            id: 10,
            order: 10,
            question: $t("questionnaire.a10.title"),
            slug: "a10",
            answers: [
              {
                name: $t("questionnaire.a10.option1"),
                value: "assets_bad1",
                points: 0,
              },
              {
                name: $t("questionnaire.a10.option2"),
                value: "assets_bad2",
                points: 0,
              },
              {
                name: $t("questionnaire.a10.option3"),
                value: "assets_righ11",
                points: 1,
              },
            ],
          },
          {
            id: 11,
            order: 11,
            question: $t("questionnaire.a11.title"),
            slug: "a11",
            answers: [
              {
                name: $t("questionnaire.a11.option1"),
                value: "stocks_bad1",
                points: 0,
              },
              {
                name: $t("questionnaire.a11.option2"),
                value: "stocks_right",
                points: 1,
              },
              {
                name: $t("questionnaire.a11.option3"),
                value: "stocks_bad2",
                points: 12,
              },
            ],
          },
          {
            id: 12,
            order: 12,
            question: $t("questionnaire.a12.title"),
            slug: "a12",
            answers: [
              {
                name: $t("questionnaire.a12.option1"),
                value: "bond_bad1",
                points: 0,
              },
              {
                name: $t("questionnaire.a12.option2"),
                value: "bond_bad2",
                points: 0,
              },
              {
                name: $t("questionnaire.a12.option3"),
                value: "bond_right",
                points: 1,
              },
            ],
          },
        ],
      },
      {
        id: 2,
        order: 2,
        category: "B",
        title: $t("questionnaire.section-2.title"),
        perex: $t("questionnaire.section-2.perex"),
        description1: $t("questionnaire.section-2.description1"),
        questions: [
          {
            id: 1,
            order: 1,
            question: $t("questionnaire.b1.title"),
            slug: "b1",
            multiple: true,
            answers: [
              {
                name: $t("questionnaire.b1.option1"),
                value: "protection",
                points: 0,
              },
              {
                name: $t("questionnaire.b1.option2"),
                value: "valuation",
                points: 3,
              },
              {
                name: $t("questionnaire.b1.option3"),
                value: "income",
                points: 0,
              },
              {
                name: $t("questionnaire.b1.option4"),
                value: "speculation",
                points: 10,
              },
            ],
          },
          {
            id: 2,
            order: 2,
            question: $t("questionnaire.b2.title"),
            slug: "b2",
            answers: [
              {
                name: $t("questionnaire.b2.option1"),
                value: "to_3%",
                points: 0,
              },
              {
                name: $t("questionnaire.b2.option2"),
                value: "from_3_to_7",
                points: 3,
              },
              {
                name: $t("questionnaire.b2.option3"),
                value: "more_then_7",
                points: 5,
              },
            ],
          },
          {
            id: 3,
            order: 3,
            question: $t("questionnaire.b3.title"),
            slug: "b3",
            answers: [
              {
                name: $t("questionnaire.b3.option1"),
                value: "less_then_year",
                points: 0,
              },
              {
                name: $t("questionnaire.b3.option2"),
                value: "from_1_to_5_years",
                points: 3,
              },
              {
                name: $t("questionnaire.b3.option3"),
                value: "from_5_to_10_years",
                points: 7,
              },
              {
                name: $t("questionnaire.b3.option4"),
                value: "more_then_10_year4",
                points: 10,
              },
            ],
          },
          {
            id: 4,
            order: 4,
            question: $t("questionnaire.b4.title"),
            slug: "b4",
            multiple: true,
            answers: [
              {
                name: $t("questionnaire.b4.option1"),
                value: "one_time",
                points: 0,
              },
              {
                name: $t("questionnaire.b4.option2"),
                value: "regular",
                points: 5,
              },
              {
                name: $t("questionnaire.b4.option3"),
                value: "active",
                points: 7,
              },
            ],
          },
        ],
      },
      {
        id: 3,
        order: 3,
        category: "C",
        title: $t("questionnaire.section-3.title"),
        perex: $t("questionnaire.section-3.perex"),
        questions: [
          {
            id: 1,
            order: 1,
            question: $t("questionnaire.c1.title"),
            slug: "c1",
            answers: [
              {
                name: $t("questionnaire.c1.option1"),
                value: "none_risk",
                points: -10,
              },
              {
                name: $t("questionnaire.c1.option2"),
                value: "normal",
                points: 3,
              },
              {
                name: $t("questionnaire.c1.option3"),
                value: "extreme",
                points: 7,
              },
            ],
          },
          {
            id: 2,
            order: 2,
            question: $t("questionnaire.c2.title"),
            slug: "c2",
            answers: [
              {
                name: $t("questionnaire.c2.option1"),
                value: "-10%",
                points: 0,
              },
              {
                name: $t("questionnaire.c2.option2"),
                value: "-30%",
                points: 3,
              },
              {
                name: $t("questionnaire.c2.option3"),
                value: "more_then_-30%",
                points: 7,
              },
            ],
          },
          {
            id: 3,
            order: 3,
            question: $t("questionnaire.c3.title"),
            slug: "c3",
            answers: [
              {
                name: $t("questionnaire.c3.option1"),
                value: "pussy",
                points: -10,
              },
              {
                name: $t("questionnaire.c3.option2"),
                value: "wait",
                points: 3,
              },
              {
                name: $t("questionnaire.c3.option3"),
                value: "boss",
                points: 7,
              },
            ],
          },
          {
            id: 4,
            order: 4,
            question: $t("questionnaire.c4.title"),
            slug: "c4",
            answers: [
              {
                name: $t("questionnaire.c4.option1"),
                value: "no_change",
                points: 0,
              },
              {
                name: $t("questionnaire.c4.option2"),
                value: "lower",
                points: -5,
              },
              {
                name: $t("questionnaire.c4.option3"),
                value: "homeless",
                points: -10,
              },
            ],
          }
        ],
      },
      {
        id: 4,
        order: 4,
        category: "D",
        title: $t("questionnaire.section-4.title"),
        perex: $t("questionnaire.section-4.perex"),
        description1: $t("questionnaire.section-4.description1"),
        questions: [
          {
            id: 1,
            order: 1,
            question: $t("questionnaire.d1.title"),
            slug: "d1",
            answers: [
              {
                name: $t("questionnaire.d1.option1"),
                value: "2000",
                points: 1,
              },
              {
                name: $t("questionnaire.d1.option2"),
                value: "from_2k_to5k",
                points: 3,
                hasInput: true,
                inputLabel: $t("questionnaire.d1.placeholder"),
                required: true,
              },
              {
                name: $t("questionnaire.d1.option3"),
                value: "more_then_5k",
                points: 5,
                hasInput: true,
                inputLabel: $t("questionnaire.d1.placeholder"),
                required: true,
              },
            ],
          },
          {
            id: 2,
            order: 2,
            question: $t("questionnaire.d2.title"),
            slug: "d2",
            multiple: true,
            answers: [
              {
                name: $t("questionnaire.d2.option1"),
                value: "employee",
                points: 0,
              },
              {
                name: $t("questionnaire.d2.option2"),
                value: "business",
                points: 0,
              },
              {
                name: $t("questionnaire.d2.option3"),
                value: "other",
                points: -2,
              },
            ],
          },
          {
            id: 3,
            order: 3,
            question: $t("questionnaire.d3.title"),
            slug: "d3",
            answers: [
              {
                name: $t("questionnaire.d3.option1"),
                value: "less_then_100k",
                points: 0,
              },
              {
                name: $t("questionnaire.d3.option2"),
                value: "from_100k_to_500k",
                points: 3,
              },
              {
                name: $t("questionnaire.d3.option3"),
                value: "more_then_500k",
                points: 7,
              },
            ],
          },
          {
            id: 4,
            order: 4,
            question: $t("questionnaire.d4.title"),
            slug: "d4",
            answers: [
              {
                name: $t("questionnaire.d4.option1"),
                value: "more_then_20%",
                points: 5,
              },
              {
                name: $t("questionnaire.d4.option2"),
                value: "less_then_20%",
                points: 3,
              },
              {
                name: $t("questionnaire.d4.option3"),
                value: "none",
                points: -5,
              },
            ],
          },
          {
            id: 5,
            order: 5,
            question: $t("questionnaire.d5.title"),
            slug: "d5",
            answers: [
              {
                name: $t("questionnaire.d5.option1"),
                value: "own_capital",
                points: 3,
              },
              {
                name: $t("questionnaire.d5.option2"),
                value: "bank",
                points: -2,
              },
              {
                name: $t("questionnaire.d5.option3"),
                value: "third_person",
                points: 0,
              },
            ],
          },
        ],
      },
      {
        id: 5,
        order: 5,
        category: "E",
        title: $t("questionnaire.section-5.title"),
        perex: $t("questionnaire.section-5.perex"),
        questions: [
          {
            id: 1,
            order: 1,
            question: $t("questionnaire.sustainability_preferences"),
            slug: "sustainability_preferences",
            answers: [
              {
                name: $t("questionnaire.sustainability_preferences_yes"),
                value: "yes",
                points: 0,
              },
              {
                name: $t("questionnaire.sustainability_preferences_no"),
                value: "no",
                points: 0,
              },
            ],
          },
          {
            id: 2,
            order: 2,
            question: $t("questionnaire.financial_instruments"),
            slug: "financial_instruments",
            sub: true,
            where: {
              sustainability_preferences: "yes",
            },
            notRequired: true,
            questions: [
              {
                id: 1,
                order: "a)",
                question: $t(
                  "questionnaire.financial_instruments_environmental"
                ),
                slug: "financial_instruments_environmental",
                answers: [
                  {
                    name: $t("questionnaire.sustainability_preferences_yes"),
                    value: "yes",
                    points: 0,
                  },
                  {
                    name: $t("questionnaire.sustainability_preferences_no"),
                    value: "no",
                    points: 0,
                  },
                ],
              },
              {
                id: 2,
                order: "",
                question: $t(
                  "questionnaire.financial_instruments_environmental_share"
                ),
                slug: "financial_instruments_environmental_share",
                where: {
                  financial_instruments_environmental: "yes",
                },
                answers: [
                  {
                    name: $t(
                      "questionnaire.financial_instruments_environmental_share_dont_care"
                    ),
                    value: "dont_care",
                    points: 0,
                  },
                  {
                    name: $t(
                      "questionnaire.financial_instruments_environmental_share_20"
                    ),
                    value: "20",
                    points: 0,
                  },
                  {
                    name: $t(
                      "questionnaire.financial_instruments_environmental_share_50"
                    ),
                    value: "50",
                    points: 0,
                  },
                ],
              },
              {
                id: 3,
                order: "b)",
                question: $t("questionnaire.financial_instruments_every"),
                slug: "financial_instruments_every",
                answers: [
                  {
                    name: $t("questionnaire.sustainability_preferences_yes"),
                    value: "yes",
                    points: 0,
                  },
                  {
                    name: $t("questionnaire.sustainability_preferences_no"),
                    value: "no",
                    points: 0,
                  },
                ],
              },
              {
                id: 4,
                order: "",
                question: $t(
                  "questionnaire.financial_instruments_environmental_share"
                ),
                slug: "financial_instruments_every_share",
                where: {
                  financial_instruments_every: "yes",
                },
                answers: [
                  {
                    name: $t(
                      "questionnaire.financial_instruments_environmental_share_dont_care"
                    ),
                    value: "dont_care",
                    points: 0,
                  },
                  {
                    name: $t(
                      "questionnaire.financial_instruments_environmental_share_20"
                    ),
                    value: "20",
                    points: 0,
                  },
                  {
                    name: $t(
                      "questionnaire.financial_instruments_environmental_share_50"
                    ),
                    value: "50",
                    points: 0,
                  },
                ],
              },
              {
                id: 5,
                order: "c)",
                question: $t("questionnaire.financial_instruments_dont"),
                slug: "financial_instruments_dont",
                answers: [
                  {
                    name: $t("questionnaire.sustainability_preferences_yes"),
                    value: "yes",
                    points: 0,
                  },
                  {
                    name: $t("questionnaire.sustainability_preferences_no"),
                    value: "no",
                    points: 0,
                  },
                ],
              },
              {
                id: 6,
                order: "",
                question: $t(
                  "questionnaire.financial_instruments_environmental_share"
                ),
                slug: "financial_instruments_dont_share",
                where: {
                  financial_instruments_dont: "yes",
                },
                answers: [
                  {
                    name: $t(
                      "questionnaire.financial_instruments_environmental_share_dont_care"
                    ),
                    value: "dont_care",
                    points: 0,
                  },
                  {
                    name: $t(
                      "questionnaire.financial_instruments_environmental_share_20"
                    ),
                    value: "20",
                    points: 0,
                  },
                  {
                    name: $t(
                      "questionnaire.financial_instruments_environmental_share_50"
                    ),
                    value: "50",
                    points: 0,
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
];
