import i18n from "@/i18n";
import store from "@/store";
import api from "@/store/api";

export async function handleApiResponse<T>(
  apiPath: string,
  store: any,
  payload: any,
  token: any,
  config: {
    successMessage?: string;
    successCallback?: CallableFunction;
    successTitle?: string;
    errorMessage?: string;
    errorCallback?: CallableFunction;
    errorTitle?: string;
  }
): Promise<void> {
  try {
    const response = await api.post(apiPath, payload, token);
    showAlert(
      "success",
      config.successMessage || $t("request_success"),
      config.successTitle || ""
    );

    // Call callback function if defined
    if (config.successCallback) config.successCallback(response);
  } catch (error) {
    showAlert(
      "error",
      error.response.data.result || $t("error_occurred"),
      config.errorTitle || ""
    );
    if (config.errorCallback) config.errorCallback(error);
  }
}

export function showAlert(
  type: string,
  alertText: string | undefined,
  alertTitle: string | undefined
) {
  return store.dispatch(
    `alerts/${type}`,
    {
      title: alertTitle,
      text: alertText,
    },
    { root: true }
  );
}

export const toCamelCase = function (key: string) {
  return key.replace(/([_][a-z])/gi, ($1) => {
    return $1.toUpperCase().replace("_", "");
  });
};

export const $t = i18n.global.t;
