import { NavigationGuardNext, RouteLocationNormalized } from "vue-router";

export function isAuthenticated(): boolean {
  if (getAuthToken()) {
    return true;
  }
  return false;
}

export function getAuthToken(): string | null {
  return localStorage.getItem("AuthToken");
}

export function setAuthToken(token: string): void {
  removeAuthToken();
  if (token) {
    localStorage.setItem("AuthToken", token);
  }
}

export function removeAuthToken(): void {
  localStorage.removeItem("AuthToken");
}

export function getLeadToken(): string | null {
  return localStorage.getItem("LeadToken");
}

export function setLeadToken(token: string): void {
  removeLeadToken();
  if (token) {
    localStorage.setItem("LeadToken", token);
  }
}

export function removeLeadToken(): void {
  localStorage.removeItem("LeadToken");
}

export function routeGuard(
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
): void {
  const excludedPaths = [
    "questionnaire-info",
    "questionnaire-evaluation",
    "investment-questionnaire",
    "update-thank-you",
  ];
  const currentPath = to.path;

  if (excludedPaths.some((path) => currentPath.includes(path))) {
    next(); // Bypass authentication check for excluded paths
  } else {
    if (isAuthenticated()) {
      if (to.name === "Questionnaire" && from.name !== "QuestionnaireInfo") {
        next({ name: "QuestionnaireInfo" });
      } else if (
        to.name === "QuestionnaireEvaluation" &&
        from.name !== "Questionnaire"
      ) {
        next({ name: "QuestionnaireInfo" });
      } else if (
        to.name === "ChildPersonalInfo" &&
        from.name !== "PersonalInfo"
      ) {
        next({ name: "PersonalInfo" });
      } else {
        next(); // Allow to enter route
      }
    } else {
      next("/"); // Go to landing page
    }
  }
  window.scrollTo(0, 0);
}
