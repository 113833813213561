import { FAQ } from "@/shared/types/FAQ";
import { $t } from "@/utils/helpers";

export const FAQs: FAQ[] = [
  {
    language: "sk",
    pages: [
      {
        name: "Registration",
        cards: [
          {
            id: 1,
            order: 1,
            question: "Prečo potrebujete môj e-mail?",
            answer:
              "Email je primárny spôsob, ako sa vami budeme komunikovať. Je preto dobré, aby ste uviedli váš primárny email, na ktorom vás môžeme naozaj zastihnúť.",
          },
          {
            id: 2,
            order: 2,
            question:
              "Kde nájdem všetky dokumenty súvisiace so založením učtu?",
            answer:
              "Predzmluvné informácie, Všeobecné obchodné podmienky, Cenník a ostatné dokumenty nájdete na našej webovej stránke v časti Dokumenty.",
          },
          {
            id: 3,
            order: 3,
            question: "Je založenie WEM digitálneho účtu spoplatnené?",
            answer:
              "Nie. Založenie ako aj prípadné zrušenie WEM digitálneho účtu je bez akýchkoľvek poplatkov a k ničomu Vás nezaväzuje.",
          },
        ],
      },
      {
        name: "PersonalInfo",
        cards: [
          {
            id: 1,
            order: 1,
            question: "Prečo potrebujete moje telefónne číslo?",
            answer:
              "Bezpečnosť klientskych údajov a investovaných prostriedkov je pre nás na prvom mieste. Preto budeme v prípade potreby používať vaše telefónne číslo na zasielanie overovacích kódov, ku ktorým by ste mali mať prístup iba vy.",
          },
          {
            id: 2,
            order: 2,
            question: "Sú moje osobné údaje u vás v bezpečí?",
            answer:
              "O bezpečnosť svojich údajov sa nemusíte obávať. Sme regulovaný finančný subjekt so silným dôrazom na bezpečnosť osobných údajov podľa platnej legislatívy.",
          },
          {
            id: 3,
            order: 3,
            question:
              "Aké má výhody otvoriť si digitálny investičný účet pre dieťa?",
            answer:
              "Žiadne. Možnosť uzatvoriť Zmluvu o riadení portfólia pre dieťa poskytujeme klientom, ktorí majú záujem o tvorbu bohatstva svojich detí, ktoré bude patriť im po dosiahnutí dospelosti.",
          },
        ],
      },
      {
        name: "AddressAndBankAccount",
        cards: [
          {
            id: 1,
            order: 1,
            question: "Sú moje osobné údaje u vás v bezpečí?",
            answer:
              "O bezpečnosť svojich údajov sa nemusíte obávať. Sme regulovaný finančný subjekt so silným dôrazom na bezpečnosť osobných údajov podľa platnej legislatívy.",
          },
          {
            id: 2,
            order: 2,
            question: "Prečo potrebujete môj bankový účet?",
            answer:
              "Legislatívne požiadavky nám udávajú povinnosť kontrolovať zdroj a pôvod investovaných prostriedkov. Preto môžete na svoj digitálny WEM investičný účet zasielať prostriedky výlučne z bankového účtu, ktorý zadáte v tomto kroku.",
          },
          {
            id: 3,
            order: 3,
            question:
              "Čo ak nemám bankový účet, resp. uzatváram zmluvu pre svoje dieťa?",
            answer:
              "V prípade že uzatvárate Zmluvu pre svoje dieťa ako jeho zákonný zástupca, uveďte prosím váš účet, z ktorého budete posielať prostriedky na investovanie, prípadne účet inej, spriaznenej osoby (rodinný príslušník).",
          },
        ],
      },
      {
        name: "QuestionnaireInfo",
        cards: [
          {
            id: 1,
            order: 1,
            question: "Je potrebné vyplniť investičný dotazník?",
            answer:
              "Investičný dotazník je jedným z najdôležitejších dokumentov, ako môžeme zhodnotiť váš vzťah k riziku a očakávanému výnosu. Preto vás prosíme, aby ste ho vyplnili tak dôsledne, aby vaše odpovede čo najlepšie vystihovali vaše očakávania od investície.",
          },
          {
            id: 2,
            order: 2,
            question: "Môžem svoje odpovede v dotazníku následne meniť?",
            answer:
              "Investičný dotazník vám po uzatvorení zmluvy o riadení portfólia ponúkneme na aktualizáciu každý rok, aby sme si boli istí, že vaša súčasná investícia stále vyhovuje vašim požiadavkám a očakávaniam. Zároveň nám prosím kedykoľvek oznámte akúkoľvek zmenu, ktorá by mohla mať vplyv na vaše investičné očakávania alebo vzťah k riziku (napr. nástup do dôchodku, znížený príjem v dôsledku nezamestnanosti a pod.)",
          },
          {
            id: 3,
            order: 3,
            question:
              "Čo ak uzatváram zmluvu pre dieťa ako jeho zákonný zástupca?",
            answer:
              "V prípade, že ste si zvolili možnosť uzatvoriť zmluvu pre vaše dieťa, v investičnom dotazníku vyplňte údaje za vás, ako zákonného zástupcu dieťaťa.",
          },
        ],
      },
      {
        name: "Questionnaire",
        cards: [
          {
            id: 1,
            order: 1,
            question: "Je potrebné vyplniť investičný dotazník?",
            answer:
              "Investičný dotazník je jedným z najdôležitejších dokumentov, ako môžeme zhodnotiť váš vzťah k riziku a očakávanému výnosu. Preto vás prosíme, aby ste ho vyplnili tak dôsledne, aby vaše odpovede čo najlepšie vystihovali vaše očakávania od investície.",
          },
          {
            id: 2,
            order: 2,
            question: "Môžem svoje odpovede v dotazníku následne meniť?",
            answer:
              "Investičný dotazník vám po uzatvorení zmluvy o riadení portfólia ponúkneme na aktualizáciu každý rok, aby sme si boli istí, že vaša súčasná investícia stále vyhovuje vašim požiadavkám a očakávaniam. Zároveň nám prosím kedykoľvek oznámte akúkoľvek zmenu, ktorá by mohla mať vplyv na vaše investičné očakávania alebo vzťah k riziku (napr. nástup do dôchodku, znížený príjem v dôsledku nezamestnanosti a pod.)",
          },
          {
            id: 3,
            order: 3,
            question:
              "Čo ak uzatváram zmluvu pre dieťa ako jeho zákonný zástupca?",
            answer:
              "V prípade, že ste si zvolili možnosť uzatvoriť zmluvu pre vaše dieťa, v investičnom dotazníku vyplňte údaje za vás, ako zákonného zástupcu dieťaťa.",
          },
        ],
      },
      {
        name: "QuestionnaireEvaluation",
        cards: [
          {
            id: 1,
            order: 1,
            question: "Čo pre mňa znamená vyhodnotenie rizikového profilu?",
            answer:
              "Vyhodnotenie vášho rizikového profilu vychádza z vami zadaných otázok v investičnom dotazníku. Rizikový profil je určujúcim faktorom k tomu, aby sme mohli správne vyhodnotiť, ktoré investičné stratégie sú pre vás vhodné.",
          },
          {
            id: 2,
            order: 2,
            question: "Čo ak nesúhlasím s vyhodnotením rizikového profilu?",
            answer:
              "Vyhodnotenie vášho rizikového profilu vychádza z vami zadaných otázok v investičnom dotazníku. Ak ste presvedčený, že ste na niektoré otázky odpovedali nesprávne, resp. neúplne, pre opätovné vyplnenie dotazníka nás prosím kontaktujte emailom na adrese frontoffice@wem.sk.",
          },
        ],
      },
      {
        name: "AddNewInvestmentStrategy.vue",
        cards: [
          {
            id: 1,
            order: 1,
            question: "Neviem, akú stratégiu si mám vybrať.",
            answer:
              "V prípade, že si nie ste istý, ktorá z ponúknutých stratégií je pre vás najvhodnejšia, nezvoľte si žiadnu a my vás budeme následne kontaktovať, aby sme vám pomohli so správnym výberom stratégie.",
          },
          {
            id: 2,
            order: 2,
            question: "Je zobrazený výnos stratégie garantovaný aj do budúcna?",
            answer:
              "Nie. Zobrazované priemerné zhodnotenie stratégie je jej historická výkonnosť. Historická výkonnosť stratégie nie je garanciou jej budúcej výkonnosti. Investovanie do cenných papierov je spojené s rizikom straty investovaných finančných prostriedkov.",
          },
          {
            id: 3,
            order: 3,
            question: "Zobrazuje sa mi málo stratégií na výber.",
            answer:
              "Zobrazili sme vám iba tie stratégie, ktoré sú pre vás vhodné z pohľadu vášho rizikového profilu. Ak sa potrebujete poradiť ohľadom výberu ďalších stratégií, kontaktujte nás emailom na adrese ffrontoffice@wem.sk.",
          },
        ],
      },
      {
        name: "DocumentsUpload",
        cards: [
          {
            id: 1,
            order: 1,
            question: "Sú moje osobné údaje u vás v bezpečí?",
            answer:
              "O bezpečnosť svojich údajov sa nemusíte obávať. Sme regulovaný finančný subjekt so silným dôrazom na bezpečnosť osobných údajov podľa platnej legislatívy.",
          },
          {
            id: 2,
            order: 2,
            question: "Prečo potrebujete môj bankový účet?",
            answer:
              "Legislatívne požiadavky nám udávajú povinnosť kontrolovať zdroj a pôvod investovaných prostriedkov. Preto môžete na svoj digitálny WEM investičný účet zasielať prostriedky výlučne z bankového účtu, ktorý zadáte v tomto kroku.",
          },
          {
            id: 3,
            order: 3,
            question:
              "Čo ak nemám bankový účet, resp. uzatváram zmluvu pre svoje dieťa?",
            answer:
              "V prípade že uzatvárate Zmluvu pre svoje dieťa ako jeho zákonný zástupca, uveďte prosím váš účet, z ktorého budete posielať prostriedky na investovanie, prípadne účet inej, spriaznenej osoby (rodinný príslušník).",
          },
        ],
      },
      {
        name: "Contracts",
        cards: [
          {
            id: 1,
            order: 1,
            question: "Potrebujem si vygenerované dokumenty uložiť?",
            answer:
              "Vygenerovanú zmluvnú dokumentáciu budete mať k dispozícii na stiahnutie vo vašej klientskej zóne a dokumenty ako Cenník, Predzmluvné informácie, Všeobecné obchodné podmienky a pod. sú neustále k dispozícii na našej webovej stránke. Aj napriek tomu vám však odporúčame stiahnuť si a uložiť práve vygenerovanú zmluvnú dokumentáciu.",
          },
          {
            id: 2,
            order: 2,
            question: "Na dokumentoch chýba môj podpis.",
            answer:
            "Uzatvorenie Zmluvy o riadení portfólia a ostatných dokumentov prebehne elektronicky prostredníctvom SMS kódu, preto na dokumentoch chýba váš fyzický podpis.",
          },
          {
            id: 3,
            order: 3,
            question: "Pri zobrazení zmluvnej dokumentácie došlo k chybe.",
            answer:
              " Ak máte problém so zobrazením zmluvnej dokumentácie, kontaktujte nás prosím emailom na adrese frontoffice@wem.sk a my vás budeme obratom kontaktovať.",
          },
        ],
      },
      {
        name: "ThankYou",
        cards: [
          {
            id: 1,
            order: 1,
            question: "Aké sú prihlasovacie údaje do môjho klientskeho účtu?",
            answer:
              "Do svojej klientskej zóny sa môžete prihlásiť prostredníctvom e-mailu a hesla, ktoré ste si zvolili v prvom kroku otvárania vášho WEM investičného účtu. Ak ste zabudli svoje heslo, použite funkciu zabudnutého hesla.",
          },
          {
            id: 2,
            order: 2,
            question: "Prečo sa mám prihlasovať do klientskej zóny?",
            answer:
              " Vo svojej klientskej zóne nájdete prehľad vašich investícií, ich vývoj a zhodnotenie ako aj správy, prostredníctvom ktorých vás budeme informovať o všetkom, čo sa deje na vašom investičnom účte.",
          },
        ],
      },
      {
        name: "Login",
        cards: [
          {
            id: 1,
            order: 1,
            question:
              "Ako môžem pokračovať v otváraní digitálneho WEM investičného účtu? ",
            answer:
              "Stačí, ak zadáte svoju emailovú adresu a heslo, ktoré ste si zvolili v prvom kroku registrácie a budete môcť jednoducho pokračovať tam, kde ste so svojou registráciou skončili.",
          },
        ],
      },
      {
        name: "StartVerification",
        cards: [
          {
            id: 1,
            order: 1,
            question: "Môžem sa k overeniu totožnosti kedykoľvek vrátiť?",
            answer:
              " Áno, v prípade že overenie Vašej totožnosti neukončíte teraz, môžete sa k tomuto kroku kedykoľvek vrátiť cez tlačidlo „Pokračovať v registrácii“ na stránke wsgin.wem.sk",
          },
          {
            id: 2,
            order: 2,
            question: "Čo v prípade, že sa overenie mojej totožnosti skončí neúspechom?",
            answer:
              " V prípade, že systém Vašu totožnosť nedokáže úspešne overiť, kontaktujte nás e-mailom na frontoffice@wem.sk alebo telefonicky na +421 2/32 11 85 85.",
          },
          {
            id: 3,
            order: 3,
            question: "Ako technicky prebieha overenie mojej totožnosti?",
            answer:
              " Overenie Vašej totožnosti je vykonávané prostredníctvom služby spoločnosti Veriff. Systém vyhodnocuje platnosť dokladu totožnosti, podobu Vašej tváre na fotke dokladu totožnosti a podobne. Všetky údaje sú šifrované a uchovávané v súlade s aktuálne platnou legislatívou.",
          }
        ],
      },
    ],
  },
  {
    language: "en",
    pages: [
      {
        name: "Registration",
        cards: [
          {
            id: 1,
            order: 1,
            question: "Why do you need my email? ",
            answer:
              "Email is the primary way we communicate with you. It's a good idea to provide your primary email where we can really reach out to you.",
          },
          {
            id: 2,
            order: 2,
            question:
              "Where can I find all the documents related to the investment account opening?",
            answer:
              "Pre-contractual information, General Terms and Conditions, Price List and other documents can be found on our website in the Documents section.",
          },
          {
            id: 3,
            order: 3,
            question: "Is there a charge for opening a WEM digital account?",
            answer:
              "No. The opening as well as possible termination of a WEM digital account is free of charge and does not bind you to anything.",
          },
        ],
      },
      {
        name: "PersonalInfo",
        cards: [
          {
            id: 1,
            order: 1,
            question: "Why do you need my phone number?",
            answer:
              "The security of client data and invested funds is our number one priority. Therefore, if necessary, we will use your phone number to send you verification codes, which only you should have access to.",
          },
          {
            id: 2,
            order: 2,
            question: "Is my personal information safe with you?",
            answer:
              "You don't have to worry about the security of your data. We are a regulated financial entity with a strong emphasis on the security of personal data and we take this responsibility very seriously.",
          },
          {
            id: 3,
            order: 3,
            question:
              "What are the benefits of opening a digital investment account for a child?",
            answer:
              "None. We provide the opportunity to open a WEM digital account for a child to clients who are interested in building wealth for their children, which will belong to them after reaching adulthood.",
          },
        ],
      },
      {
        name: "AddressAndBankAccount",
        cards: [
          {
            id: 1,
            order: 1,
            question: "Is my personal information safe with you?",
            answer:
              "You don't have to worry about the security of your data. We are a regulated financial entity with a strong emphasis on the security of personal data and we take this responsibility very seriously.",
          },
          {
            id: 2,
            order: 2,
            question: "Why do you need my bank account number?",
            answer:
              "Legal requirements oblige us to control the source and origin of invested funds. Therefore, you can only send funds to your digital WEM investment account from the bank account you enter in this step.",
          },
          {
            id: 3,
            order: 3,
            question:
              "What if I don't have a bank account, or I am opening a WEM digital account for my child?",
            answer:
              "If you are concluding an Agreement for your child as his / her legal representative, please state your account from which you will send funds for investment, or the account of another, related person (family member).",
          },
        ],
      },
      {
        name: "QuestionnaireInfo",
        cards: [
          {
            id: 1,
            order: 1,
            question:
              "Is it necessary to fill in the investment questionnaire?",
            answer:
              "The investment questionnaire is one of the most important documents on how we can evaluate your relationship to risk and expected return. Therefore, we ask you to fill it in carefully, so that your answers best reflect your investment expectations.",
          },
          {
            id: 2,
            order: 2,
            question:
              "Can I subsequently change my answers to the questionnaire?",
            answer:
              "After opening the WEM investment account, we will ask you to update the investment questionnaire every year to make sure that your current investment still meets your requirements and expectations. At the same time, please notify us at any time of any change that could affect your investment expectations or risk relationship (e.g. retirement, reduced income due to unemployment, etc.)",
          },
          {
            id: 3,
            order: 3,
            question:
              "What if I am concluding a contract for a child as his or her legal representative?",
            answer:
              "If you have chosen the option of concluding a contract for your child, fill in the data in the investment questionnaire for you, as the child's legal representative.",
          },
        ],
      },
      {
        name: "Questionnaire",
        cards: [
          {
            id: 1,
            order: 1,
            question:
              "Is it necessary to fill in the investment questionnaire?",
            answer:
              "The investment questionnaire is one of the most important documents on how we can evaluate your relationship to risk and expected return. Therefore, we ask you to fill it in carefully, so that your answers best reflect your investment expectations.",
          },
          {
            id: 2,
            order: 2,
            question:
              "Can I subsequently change my answers to the questionnaire?",
            answer:
              "After opening the WEM investment account, we will ask you to update the investment questionnaire every year to make sure that your current investment still meets your requirements and expectations. At the same time, please notify us at any time of any change that could affect your investment expectations or risk relationship (e.g. retirement, reduced income due to unemployment, etc.)",
          },
          {
            id: 3,
            order: 3,
            question:
              "What if I am concluding a contract for a child as his or her legal representative?",
            answer:
              "If you have chosen the option of concluding a contract for your child, fill in the data in the investment questionnaire for you, as the child's legal representative.",
          },
        ],
      },
      {
        name: "QuestionnaireEvaluation",
        cards: [
          {
            id: 1,
            order: 1,
            question: "What does the risk profile evaluation mean to me?",
            answer:
              "The evaluation of your risk profile is based on the questions you entered in the investment questionnaire. The risk profile is a determining factor in order to be able to correctly evaluate which investment strategies are suitable for you.",
          },
          {
            id: 2,
            order: 2,
            question:
              "What if I do not agree with the risk profile evaluation?",
            answer:
              "The evaluation of your risk profile is based on the questions you entered in the investment questionnaire. If you believe that you have answered some questions incorrectly, please contact us by email at frontoffice@wem.sk to fill in the questionnaire again.",
          },
        ],
      },
      {
        name: "AddNewInvestmentStrategy.vue",
        cards: [
          {
            id: 1,
            order: 1,
            question: "I don't know what strategy to choose.",
            answer:
              "If you are not sure which of the offered strategies is the most suitable for you, do not choose any and we will then contact you to help you choose the right strategy.",
          },
          {
            id: 2,
            order: 2,
            question:
              "Is the displayed strategy return guaranteed for the future?",
            answer:
              "No. The displayed average return of the strategy is its historical performance. The historical performance of the strategy is not a guarantee of its future performance. Investing in securities is associated with the risk of losing invested funds.",
          },
          {
            id: 3,
            order: 3,
            question: "There is only few strategies to choose from.",
            answer:
              "We've only shown you the strategies that are suitable for you in terms of your risk profile. If you need advice on choosing other strategies, contact us by email at frontoffice@wem.sk.",
          },
        ],
      },
      {
        name: "DocumentsUpload",
        cards: [
          {
            id: 1,
            order: 1,
            question: "Why do you need scan/photograph of my documents? ",
            answer:
              "As a financial institution, due to legislative requirements, we are obliged to verify your identity as well as the ownership of the bank account from which you will send funds to your portfolio.",
          },
          {
            id: 2,
            order: 2,
            question: "What does a politically exposed person mean?",
            answer:
              "A politically exposed person is a person who performs an important public function, such as a member of parliament, ambassador, mayor, prosecutor, judge, and so on. You will find the exact definition of a politically exposed person in the General Terms and Conditions.",
          },
          {
            id: 3,
            order: 3,
            question:
              "Can I open a digital WEM investment account as a politically exposed person?",
            answer:
              "You can open a digital WEM investment account without any problems, even if you meet the definition of a politically exposed person.",
          },
          {
            id: 4,
            order: 4,
            question: "Is my personal information safe with you?",
            answer:
              "You don't have to worry about the security of your data. We are a regulated financial entity with a strong emphasis on the security of personal data and we take this responsibility very seriously.",
          },
        ],
      },
      {
        name: "Contracts",
        cards: [
          {
            id: 1,
            order: 1,
            question: "Do I need to save all the documents?",
            answer:
              "The contractual documentation will be available for download in your client zone and documents such as Price List, Pre-contractual Information, General Terms and Conditions, etc. are always available on our website. Nevertheless, we recommend that you download and save the contractual documentation.",
          },
          {
            id: 2,
            order: 2,
            question: "My signature is missing from the documents.",
            answer:
              "The signing process of the Portfolio Management Agreement and others documents will be electronic via SMS code, therefore your signature is missing from the documents.",
          },
          {
            id: 3,
            order: 3,
            question:
              "An error occurred while viewing the contract documentation.",
            answer:
              "If you have a issues with the display of contractual documentation, please contact us by email at frontoffice@wem.sk and we will get back to you immediately.",
          },
        ],
      },
      {
        name: "ThankYou",
        cards: [
          {
            id: 1,
            order: 1,
            question: "What are the login credentials into my account?",
            answer:
              "You can log in to your client zone using the email and password you chose in the first step of opening your WEM investment account. If you have forgotten your password, use the forgotten password feature.",
          },
          {
            id: 2,
            order: 2,
            question: "Why should I log in to the client zone?",
            answer:
              " In your client zone you will find an overview of your investments, their development and evaluation, as well as messages through which we will inform you about everything that happens in your investment account.",
          },
        ],
      },
      {
        name: "StartVerification",
        cards: [
          {
            id: 1,
            order: 1,
            question: "Can I return to identity verification at any time?",
            answer:
              "Yes, if you do not complete the verification of your identity now, you can return to this step at any time via the 'Continue registration' button on the wsgin.wem.sk page",
          },
          {
            id: 2,
            order: 2,
            question: "What if my identity verification fails?",
            answer:
              "If the system cannot successfully verify your identity, contact us by e-mail at frontoffice@wem.sk or by phone at +421 2/32 11 85 85.",
          },
          {
            id: 3,
            order: 3,
            question: "How does the verification of my identity take place technically?",
            answer:
              "Verification of your identity is carried out through the Veriff service. The system evaluates the validity of the identity document, the appearance of your face on the photo of the identity document, etc. All data is encrypted and stored in accordance with the currently valid legislation.",
          }
        ],
      },
      {
        name: "Login",
        cards: [
          {
            id: 1,
            order: 1,
            question:
              "How can I continue to open a digital WEM investment account?",
            answer:
              "All you have to do is enter your email address and the password you chose in the first step of the registration and you will be able to easily continue where you left off.",
          },
        ],
      },
    ],
  },
  {
    language: "bg",
    pages: [
      {
        name: "Registration",
        cards: [
          {
            id: 1,
            order: 1,
            question: "Why do you need my email? ",
            answer:
              "Email is the primary way we communicate with you. It's a good idea to provide your primary email where we can really reach out to you.",
          },
          {
            id: 2,
            order: 2,
            question:
              "Where can I find all the documents related to the investment account opening?",
            answer:
              "Pre-contractual information, General Terms and Conditions, Price List and other documents can be found on our website in the Documents section.",
          },
          {
            id: 3,
            order: 3,
            question: "Is there a charge for opening a WEM digital account?",
            answer:
              "No. The opening as well as possible termination of a WEM digital account is free of charge and does not bind you to anything.",
          },
        ],
      },
      {
        name: "PersonalInfo",
        cards: [
          {
            id: 1,
            order: 1,
            question: "Why do you need my phone number?",
            answer:
              "The security of client data and invested funds is our number one priority. Therefore, if necessary, we will use your phone number to send you verification codes, which only you should have access to.",
          },
          {
            id: 2,
            order: 2,
            question: "Is my personal information safe with you?",
            answer:
              "You don't have to worry about the security of your data. We are a regulated financial entity with a strong emphasis on the security of personal data and we take this responsibility very seriously.",
          },
          {
            id: 3,
            order: 3,
            question:
              "What are the benefits of opening a digital investment account for a child?",
            answer:
              "None. We provide the opportunity to open a WEM digital account for a child to clients who are interested in building wealth for their children, which will belong to them after reaching adulthood.",
          },
        ],
      },
      {
        name: "AddressAndBankAccount",
        cards: [
          {
            id: 1,
            order: 1,
            question: "Is my personal information safe with you?",
            answer:
              "You don't have to worry about the security of your data. We are a regulated financial entity with a strong emphasis on the security of personal data and we take this responsibility very seriously.",
          },
          {
            id: 2,
            order: 2,
            question: "Why do you need my bank account number?",
            answer:
              "Legal requirements oblige us to control the source and origin of invested funds. Therefore, you can only send funds to your digital WEM investment account from the bank account you enter in this step.",
          },
          {
            id: 3,
            order: 3,
            question:
              "What if I don't have a bank account, or I am opening a WEM digital account for my child?",
            answer:
              "If you are concluding an Agreement for your child as his / her legal representative, please state your account from which you will send funds for investment, or the account of another, related person (family member).",
          },
        ],
      },
      {
        name: "QuestionnaireInfo",
        cards: [
          {
            id: 1,
            order: 1,
            question:
              "Is it necessary to fill in the investment questionnaire?",
            answer:
              "The investment questionnaire is one of the most important documents on how we can evaluate your relationship to risk and expected return. Therefore, we ask you to fill it in carefully, so that your answers best reflect your investment expectations.",
          },
          {
            id: 2,
            order: 2,
            question:
              "Can I subsequently change my answers to the questionnaire?",
            answer:
              "After opening the WEM investment account, we will ask you to update the investment questionnaire every year to make sure that your current investment still meets your requirements and expectations. At the same time, please notify us at any time of any change that could affect your investment expectations or risk relationship (e.g. retirement, reduced income due to unemployment, etc.)",
          },
          {
            id: 3,
            order: 3,
            question:
              "What if I am concluding a contract for a child as his or her legal representative?",
            answer:
              "If you have chosen the option of concluding a contract for your child, fill in the data in the investment questionnaire for you, as the child's legal representative.",
          },
        ],
      },
      {
        name: "Questionnaire",
        cards: [
          {
            id: 1,
            order: 1,
            question:
              "Is it necessary to fill in the investment questionnaire?",
            answer:
              "The investment questionnaire is one of the most important documents on how we can evaluate your relationship to risk and expected return. Therefore, we ask you to fill it in carefully, so that your answers best reflect your investment expectations.",
          },
          {
            id: 2,
            order: 2,
            question:
              "Can I subsequently change my answers to the questionnaire?",
            answer:
              "After opening the WEM investment account, we will ask you to update the investment questionnaire every year to make sure that your current investment still meets your requirements and expectations. At the same time, please notify us at any time of any change that could affect your investment expectations or risk relationship (e.g. retirement, reduced income due to unemployment, etc.)",
          },
          {
            id: 3,
            order: 3,
            question:
              "What if I am concluding a contract for a child as his or her legal representative?",
            answer:
              "If you have chosen the option of concluding a contract for your child, fill in the data in the investment questionnaire for you, as the child's legal representative.",
          },
        ],
      },
      {
        name: "QuestionnaireEvaluation",
        cards: [
          {
            id: 1,
            order: 1,
            question: "What does the risk profile evaluation mean to me?",
            answer:
              "The evaluation of your risk profile is based on the questions you entered in the investment questionnaire. The risk profile is a determining factor in order to be able to correctly evaluate which investment strategies are suitable for you.",
          },
          {
            id: 2,
            order: 2,
            question:
              "What if I do not agree with the risk profile evaluation?",
            answer:
              "The evaluation of your risk profile is based on the questions you entered in the investment questionnaire. If you believe that you have answered some questions incorrectly, please contact us by email at frontoffice@wem.sk to fill in the questionnaire again.",
          },
        ],
      },
      {
        name: "AddNewInvestmentStrategy.vue",
        cards: [
          {
            id: 1,
            order: 1,
            question: "I don't know what strategy to choose.",
            answer:
              "If you are not sure which of the offered strategies is the most suitable for you, do not choose any and we will then contact you to help you choose the right strategy.",
          },
          {
            id: 2,
            order: 2,
            question:
              "Is the displayed strategy return guaranteed for the future?",
            answer:
              "No. The displayed average return of the strategy is its historical performance. The historical performance of the strategy is not a guarantee of its future performance. Investing in securities is associated with the risk of losing invested funds.",
          },
          {
            id: 3,
            order: 3,
            question: "There is only few strategies to choose from.",
            answer:
              "We've only shown you the strategies that are suitable for you in terms of your risk profile. If you need advice on choosing other strategies, contact us by email at frontoffice@wem.sk.",
          },
        ],
      },
      {
        name: "DocumentsUpload",
        cards: [
          {
            id: 1,
            order: 1,
            question: "Why do you need scan/photograph of my documents? ",
            answer:
              "As a financial institution, due to legislative requirements, we are obliged to verify your identity as well as the ownership of the bank account from which you will send funds to your portfolio.",
          },
          {
            id: 2,
            order: 2,
            question: "What does a politically exposed person mean?",
            answer:
              "A politically exposed person is a person who performs an important public function, such as a member of parliament, ambassador, mayor, prosecutor, judge, and so on. You will find the exact definition of a politically exposed person in the General Terms and Conditions.",
          },
          {
            id: 3,
            order: 3,
            question:
              "Can I open a digital WEM investment account as a politically exposed person?",
            answer:
              "You can open a digital WEM investment account without any problems, even if you meet the definition of a politically exposed person.",
          },
          {
            id: 4,
            order: 4,
            question: "Is my personal information safe with you?",
            answer:
              "You don't have to worry about the security of your data. We are a regulated financial entity with a strong emphasis on the security of personal data and we take this responsibility very seriously.",
          },
        ],
      },
      {
        name: "Contracts",
        cards: [
          {
            id: 1,
            order: 1,
            question: "Do I need to save all the documents?",
            answer:
              "The contractual documentation will be available for download in your client zone and documents such as Price List, Pre-contractual Information, General Terms and Conditions, etc. are always available on our website. Nevertheless, we recommend that you download and save the contractual documentation.",
          },
          {
            id: 2,
            order: 2,
            question: "My signature is missing from the documents.",
            answer:
              "The signing process of the Portfolio Management Agreement and others documents will be electronic via SMS code, therefore your signature is missing from the documents.",
          },
          {
            id: 3,
            order: 3,
            question:
              "An error occurred while viewing the contract documentation.",
            answer:
              "If you have a issues with the display of contractual documentation, please contact us by email at frontoffice@wem.sk and we will get back to you immediately.",
          },
        ],
      },
      {
        name: "ThankYou",
        cards: [
          {
            id: 1,
            order: 1,
            question: "What are the login credentials into my account?",
            answer:
              "You can log in to your client zone using the email and password you chose in the first step of opening your WEM investment account. If you have forgotten your password, use the forgotten password feature.",
          },
          {
            id: 2,
            order: 2,
            question: "Why should I log in to the client zone?",
            answer:
              " In your client zone you will find an overview of your investments, their development and evaluation, as well as messages through which we will inform you about everything that happens in your investment account.",
          },
        ],
      },
      {
        name: "StartVerification",
        cards: [
          {
            id: 1,
            order: 1,
            question: "Can I return to identity verification at any time?",
            answer:
              "Yes, if you do not complete the verification of your identity now, you can return to this step at any time via the 'Continue registration' button on the wsgin.wem.sk page",
          },
          {
            id: 2,
            order: 2,
            question: "What if my identity verification fails?",
            answer:
              "If the system cannot successfully verify your identity, contact us by e-mail at frontoffice@wem.sk or by phone at +421 2/32 11 85 85.",
          },
          {
            id: 3,
            order: 3,
            question: "How does the verification of my identity take place technically?",
            answer:
              "Verification of your identity is carried out through the Veriff service. The system evaluates the validity of the identity document, the appearance of your face on the photo of the identity document, etc. All data is encrypted and stored in accordance with the currently valid legislation.",
          }
        ],
      },
      {
        name: "Login",
        cards: [
          {
            id: 1,
            order: 1,
            question:
              "How can I continue to open a digital WEM investment account?",
            answer:
              "All you have to do is enter your email address and the password you chose in the first step of the registration and you will be able to easily continue where you left off.",
          },
        ],
      },
    ],
  },
];
