
import { Options, Vue } from "vue-class-component";
@Options({
  props: {
    question: {
      required: true,
    },
    answer: {
      required: false,
    },
  },
  methods: {},
})
export default class FooterCard extends Vue {}
