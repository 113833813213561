import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-d740eacc")
const _hoisted_1 = { class: "alerts" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = { class: "font-bold text-sm mb-1 title" }
const _hoisted_4 = { class: "text-xs" }
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.alerts, (alert) => {
      return (_openBlock(), _createElementBlock("div", {
        class: _normalizeClass('alert ' + alert.type),
        key: alert.key,
        onClick: ($event: any) => (_ctx.deleteNotification(alert.key))
      }, [
        _createElementVNode("p", _hoisted_3, _toDisplayString(alert.title), 1),
        _createElementVNode("p", _hoisted_4, _toDisplayString(alert.text), 1)
      ], 10, _hoisted_2))
    }), 128))
  ]))
}