
import { Options, Vue } from "vue-class-component";
import { getLocale } from "@/i18n";
@Options({
  data() {
    return {
      isLanguageDropdownOpen: false,
      avaialbleLanguages: [
        { name: "slovenčina", code: "sk" },
        { name: "english", code: "en" },
        // { name: "български", code: "bg" },
      ],
    };
  },
  computed: {
    activeLanguage() {
      return this.$i18n.locale;
    },
    isNotQuestionnairePage() {
      return !this.$route.fullPath.includes('/investment-questionnaire/') && !this.$route.fullPath.includes('sk/questionnaire-evaluation') && !this.$route.fullPath.includes('en/questionnaire-evaluation');
    }
  },
  methods: {
    toggleLanguageDropdown() {
      this.isLanguageDropdownOpen = !this.isLanguageDropdownOpen;
    },
    closeLanguageDropdown() {
      this.isLanguageDropdownOpen = false;
    },
    selectLanguage(language: string) {
      const actualLocale = getLocale();
      localStorage.setItem("wem_locale", language);
      this.$i18n.locale = language;
      if (actualLocale != language) {
        location.reload();
      }
    },
  },
})
export default class LanguageSwitcher extends Vue {}
