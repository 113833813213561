<template>
  <div class="maintenance-container background-cube h-full relative">
    <!--    <font-awesome-icon icon="fa-solid fa-screwdriver-wrench" class="maintenance-icon"/>-->
    <h1 class="maintenance-title primary" style="color: #13294b">
      {{ $t("maintenance-title") }}
    </h1>
    <p class="maintenance-text primary" style="color: #13294b">{{ $t("maintenance") }}</p>
  </div>
</template>

<script>
export default {
  name: "MaintenancePage",
};
</script>

<style scoped lang="scss">
.maintenance-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: linear-gradient(135deg, #e9e9e9, #ffffff);
  color: #333;
  text-align: center;
  padding: 20px;
  animation: fadeIn 1s ease-in-out;

  .maintenance-icon {
    height: 100px;
    width: 100px;
    color: #333;
    margin-bottom: 30px;
    animation: bounce 2s infinite;

    @keyframes bounce {
      0%,
      100% {
        transform: translateY(0);
      }
      50% {
        transform: translateY(-10px);
      }
    }
  }

  .maintenance-title {
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 20px;
    color: #000000;
    letter-spacing: 1px;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
    animation: fadeInDown 1s ease-in-out;
  }

  .maintenance-text {
    font-size: 1.3rem;
    max-width: 600px;
    color: #333;
    line-height: 1.6;
    animation: fadeInUp 1s ease-in-out;
    text-shadow: 0.5px 0.5px 1px rgba(0, 0, 0, 0.05);
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @keyframes fadeInDown {
    from {
      opacity: 0;
      transform: translateY(-20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  @keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
}
</style>
