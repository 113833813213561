import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "w-full relative background-cube" }
const _hoisted_2 = { class: "md:flex md:justify-between relative z-10" }
const _hoisted_3 = { class: "md:h-1/2 md:w-4/12 w-full" }
const _hoisted_4 = { class: "\n          w-full\n          md:max-w-xl\n          md:w-7/12\n          md:border\n          md:shadow\n          md:p-14\n          md:flex\n          md:flex-col\n          md:justify-between\n          md:bg-gray-100\n          border-secondary-100 border-t\n          py-12\n          mt-12\n        " }
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = { class: "w-9/12" }
const _hoisted_7 = { class: "mb-5 font-serif" }
const _hoisted_8 = { class: "font-serif" }
const _hoisted_9 = { class: "md:justify-self-end md:flex md:justify-between md:mt-20 mt-10" }
const _hoisted_10 = { class: "md:flex md:justify-between relative md:-top-44 md:left-10 sm:my-4" }
const _hoisted_11 = { class: "\n          w-full\n          md:max-w-xl\n          md:w-7/12\n          md:border\n          md:shadow\n          md:p-14\n          md:flex\n          md:flex-col\n          md:justify-between\n          md:bg-gray-100\n          border-secondary-100 border-t\n          py-12\n        " }
const _hoisted_12 = ["innerHTML"]
const _hoisted_13 = { class: "mb-5 font-serif w-10/12" }
const _hoisted_14 = { class: "justify-self-end flex justify-between mt-16" }
const _hoisted_15 = {
  class: "btn secondary inverted",
  href: "https://wem.sk/kontakt/#kontakty",
  target: "_blank"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SectionTitle = _resolveComponent("SectionTitle")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_SectionTitle, {
          class: "md:w-80",
          text: _ctx.$t('landing.mainTitle')
        }, null, 8, ["text"])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("h3", {
          class: "w-full font-medium mb-7",
          innerHTML: _ctx.$t('landing.box1Title')
        }, null, 8, _hoisted_5),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.$t("landing.box1Text")), 1),
          _createElementVNode("p", _hoisted_8, _toDisplayString(_ctx.$t("landing.box1Text2")), 1)
        ]),
        _createElementVNode("div", _hoisted_9, [
          _createVNode(_component_router_link, {
            to: "/registration",
            class: "btn primary inverted hover:bg-primary block md:inline"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("landing.box1button")), 1)
            ]),
            _: 1
          }),
          _createElementVNode("button", {
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.push('login'))),
            class: "\n              btn\n              secondary\n              link\n              text-center\n              md:right\n              mx-auto\n              mt-4\n              block\n              md:mt-0 md:mx-0 md:inline\n            "
          }, _toDisplayString(_ctx.$t("landing.continue")), 1)
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_10, [
      _createElementVNode("div", _hoisted_11, [
        _createElementVNode("h3", {
          class: "w-60 font-medium md:mb-20 mb-10",
          innerHTML: _ctx.$t('landing.box2Title')
        }, null, 8, _hoisted_12),
        _createElementVNode("p", _hoisted_13, _toDisplayString(_ctx.$t("landing.box2Text")), 1),
        _createElementVNode("div", _hoisted_14, [
          _createElementVNode("a", _hoisted_15, _toDisplayString(_ctx.$t("landing.box2button")), 1)
        ])
      ])
    ])
  ]))
}