
import { Options, Vue } from "vue-class-component";
import SectionTitle from "@/shared/components/SectionTitle.vue";

@Options({
  components: {
    SectionTitle
  },
  mounted() {
    var params = new URLSearchParams();
    params.append("scope", "onboarding");
    params.append("user", "onboarding");
    params.append("pass", "6N9Xebc0i!Nn01%*G2d5");

    this.$store.dispatch("onboarding/setAuthToken", { params });

    this.checkLocale();
  },
  watch: {
    // Watch for hash changes in the URL and update locale accordingly
    "$route.hash"(newHash) {
      this.checkLocale();
    }
  },
  methods: {
    checkLocale() {
      // Check if the hash contains '/en'
      // console.log('Route:', window.location.href);
      if ( window.location.href.includes("/en")) {
        localStorage.setItem("wem_locale", "en"); // Set wem_locale in localStorage
        this.$i18n.locale = "en"; // Set i18n language to en
      }
    }
  }
})
export default class Landing extends Vue {
}
