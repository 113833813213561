import { FAQ } from "@/shared/types/FAQ";
import { FAQs } from "../faq";

interface QuestionnaireState {
  langugage: string;
  faq: FAQ | null;
}

const initialState: QuestionnaireState = {
  langugage: "sk",
  faq: null,
};

export default {
  namespaced: true,
  state: initialState,
  getters: {
    getFAQ: (state: QuestionnaireState): FAQ | null => {
      return state.faq;
    },
  },
  actions: {
    setQuestionnaire: (store: any, language: string): void => {
      store.commit("setQuestionnaire", language);
    },
  },
  mutations: {
    setFAQ: (state: QuestionnaireState, language: string): void => {
      const faq = FAQs.find((f) => f.language === language);
      state.langugage = language;
      state.faq = faq ? faq : null;
    },
  },
};
