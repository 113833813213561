import { createStore } from "vuex";
import alerts from "@/store/modules/alerts";
import onboarding, { OnboardingState } from "@/store/modules/onboarding";
import questionnaire from "@/store/modules/questionnaire";
import faq from "@/store/modules/faq";

export interface RootState {
  onboarding: OnboardingState;
  // Add other module states here if needed
}

export default createStore({
  state: {},
  actions: {},
  mutations: {},
  modules: {
    alerts,
    onboarding,
    questionnaire,
    faq,
  },
});
