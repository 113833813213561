
import { Options, Vue } from "vue-class-component";

@Options({
  computed: {
    alerts() {
      return this.$store.getters["alerts/allAlerts"];
    },
  },
  methods: {
    deleteNotification(key: number) {
      this.$store.commit("alert/delete", key);
    },
  },
})
export default class Alert extends Vue {}
