
import { Options, Vue } from "vue-class-component";
import MenuIcon from "@/assets/icons/MenuIcon.vue";
import LanguageSwitcher from "@/components/layout/LanguageSwitcher.vue";

@Options({
  components: {
    MenuIcon,
    LanguageSwitcher,
  },
})
export default class Header extends Vue {}
