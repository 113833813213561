import { createI18n, LocaleMessages, useI18n, VueMessageType } from "vue-i18n";

function loadLocales(): LocaleMessages<VueMessageType> {
  const locales = require.context(
    "./locales",
    true,
    /[A-Za-z0-9-_,\s]+\.json$/i
  );
  const messages: LocaleMessages<VueMessageType> = {};
  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key);
    }
  });
  return messages;
}

export function getLocale(): string {
  const locale = localStorage.getItem("wem_locale");
  if (locale) {
    return locale;
  } else {
    localStorage.setItem("wem_locale", "sk");
    return "sk";
  }
}

export default createI18n({
  legacy: false,
  locale: getLocale(),
  fallbackLocale: "sk",
  globalInjection: true,
  messages: loadLocales(),
});
