<template>
  <footer
    v-if="isHomePage"
    class="overflow-x-hidden background-cube-footer relative z-10"
  >
    <div class="max-w-5xl mx-auto px-6 md:px-0">
      <div class="md:flex justify-between">
        <SectionTitle
          :text="$t('footer.title')"
          customClass="font-normal text-5xl font-sofia"
        />
        <div class="mt-8">
          <button
            class="btn px-6 py-4 border mx-2 bg-transparent text-white"
            @click="$refs.carousel.goToPrev()"
          >
            &lt;
          </button>
          <button
            class="btn px-6 py-4 border mx-2 bg-transparent text-white"
            @click="$refs.carousel.goToNext()"
          >
            &gt;
          </button>
        </div>
      </div>
      <div class="pt-12 w-full">
        <div class="w-full">
          <agile
            :options="optionsAgile"
            ref="carousel"
            class="flex"
            :key="`${footerCards.length}` - `${Math.random().toString()}`"
          >
            <div
              class="slide h-full"
              v-for="card in footerCards"
              :key="card.id"
            >
              <FooterCard :question="card.question" :answer="card.answer" />
              {{}}
            </div>
          </agile>
        </div>
      </div>
    </div>
  </footer>
</template>

<script lang="js">
import { Options, Vue } from "vue-class-component";
import SectionTitle from "@/shared/components/SectionTitle.vue";
import FooterCard from "@/components/FooterCard.vue";
import { VueAgile } from 'vue-agile'
import i18n from '@/i18n'

@Options({
  components: {
    SectionTitle,
    FooterCard,
    agile: VueAgile
  },
  data() {
    return {
      settings: {
          "arrows": false,
      },
      optionsAgile: {
        arrows: false,
        navButtons: false,
        slidesToShow: 1,
        responsive: [
          {
            breakpoint: 600,
            settings: {
                slidesToShow: 2
            },
          }
        ]
      }
    }
  },
  mounted() {
    this.$store.commit("faq/setFAQ", this.$i18n.locale);
  },
  computed: {
    isHomePage() {
      console.log(this.$route.path); // log the current route path
      return !(this.$route.path === "/" ||
        this.$route.path.startsWith("/sk/investment-questionnaire") ||
        this.$route.path.startsWith("/en/investment-questionnaire") ||
        this.$route.path.startsWith("/sk/questionnaire-evaluation") ||
        this.$route.path.startsWith("/en/questionnaire-evaluation") ||
        this.$route.path.startsWith("/sk/update-thank-you") ||
        this.$route.path.startsWith("/en/update-thank-you") ||
        this.$route.path === "/forgot-password" ||
        this.$route.path.startsWith("/sk/reset-password"));
    },

    isUpdatePage () {
      return this.$route.path === "/sk/investment-questionnaire/" ? true : false;
    },
    currentRouteName() {
        return this.$route.name;
    },
    getFAQ() {
      return this.$store.getters["faq/getFAQ"];
    },
    footerCards() {
      const page = this.getFAQ.pages.find(p => p.name === this.currentRouteName)
      return page ? page.cards : []
    }
  },
})
export default class Footer extends Vue {}
</script>

<style lang="scss" scoped>
footer {
  @apply w-full flex-shrink-0 pt-16 pb-28 text-white;
}
</style>
