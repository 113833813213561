import { routeGuard } from "@/utils/authentication";
// import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import {
  createRouter,
  createWebHashHistory,
  NavigationGuardNext,
  RouteLocationNormalized,
  RouteRecordRaw
} from "vue-router";

import Landing from "../views/Landing.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/:lang?",
    name: "Landing",
    component: Landing
  },
  {
    path: "/registration",
    name: "Registration",
    component: () =>
      import(/* webpackChunkName: "registration" */ "@/views/Registration.vue")
  },
  {
    path: "/login",
    name: "Login",
    component: () =>
      import(/* webpackChunkName: "login" */ "@/views/Login.vue")
  },
  {
    path: "/steps/personal-info",
    meta: { step: 2 },
    name: "PersonalInfo",
    beforeEnter: (to, from, next) => routeGuard(to, from, next),
    component: () =>
      import(
        /* webpackChunkName: "personal-info" */ "@/views/steps/PersonalInfo.vue"
        )
  },
  {
    path: "/steps/child-personal-info",
    meta: { step: 2 },
    name: "ChildPersonalInfo",
    beforeEnter: (to, from, next) => routeGuard(to, from, next),
    component: () =>
      import(
        /* webpackChunkName: "child-personal-info" */ "@/views/steps/ChildPersonalInfo.vue"
        )
  },
  {
    path: "/steps/address-and-bank-account",
    meta: { step: 3 },
    name: "AddressAndBankAccount",
    beforeEnter: (to, from, next) => routeGuard(to, from, next),
    component: () =>
      import(
        /* webpackChunkName: "address-and-bank-account" */ "@/views/steps/AddressAndBankAccount.vue"
        )
  },
  {
    path: "/steps/questionnaire",
    meta: { step: 4 },
    name: "Questionnaire",
    beforeEnter: (to, from, next) => routeGuard(to, from, next),
    component: () =>
      import(
        /* webpackChunkName: "questionnaire" */ "@/views/steps/questionnaire/Questionnaire.vue"
        )
  },
  {
    path: "/steps/questionnaire-evaluation",
    name: "QuestionnaireEvaluation",
    beforeEnter: (to, from, next) => routeGuard(to, from, next),
    component: () =>
      import(
        /* webpackChunkName: "questionnaire-evaluation" */ "@/views/steps/questionnaire/QuestionnaireEvaluation.vue"
        )
  },
  {
    path: "/steps/questionnaire-info",
    name: "QuestionnaireInfo",
    beforeEnter: (to, from, next) => routeGuard(to, from, next),
    component: () =>
      import(
        /* webpackChunkName: "questionnaire-info" */ "@/views/steps/questionnaire/QuestionnaireInfo.vue"
        )
  },
  {
    path: "/steps/investment-strategy",
    meta: { step: 5 },
    name: "InvestmentStrategy",
    props: true,
    beforeEnter: (to, from, next) => routeGuard(to, from, next),
    component: () =>
      import(
        /* webpackChunkName: "investment-strategy" */ "@/views/steps/InvestmentStrategy.vue"
        )
  },
  {
    path: "/steps/documents-upload",
    meta: { step: 6 },
    name: "DocumentsUpload",
    // beforeEnter: (to, from, next) => routeGuard(to, from, next),
    component: () =>
      import(
        /* webpackChunkName: "documents-upload" */ "@/views/steps/DocumentsUpload.vue"
        )
  },
  {
    path: "/steps/contracts",
    name: "Contracts",
    meta: { step: 7 },
    beforeEnter: (to, from, next) => routeGuard(to, from, next),
    component: () =>
      import(/* webpackChunkName: "contracts" */ "@/views/steps/Contracts.vue")
  },
  {
    path: "/steps/thank-you",
    meta: { step: 8 },
    name: "ThankYou",
    beforeEnter: (to, from, next) => routeGuard(to, from, next),
    component: () =>
      import(/* webpackChunkName: "thank-you" */ "@/views/steps/ThankYou.vue")
  },
  {
    path: "/steps/start-verification",
    meta: { step: 10 },
    name: "StartVerification",
    beforeEnter: (to, from, next) => routeGuard(to, from, next),
    component: () =>
      import(/* webpackChunkName: "thank-you" */ "@/views/steps/StartVerification.vue")
  },
  {
    path: "/steps/verification-result/:uuid",
    name: "VerificationResult",
    component: () =>
      import(/* webpackChunkName: "thank-you" */ "@/views/steps/VerificationResult.vue")
  },
  {
    path: "/:lang/investment-questionnaire/:token",
    // meta: { step: 8 },
    name: "UpdateQuestionnaire",
    beforeEnter: (to, from, next) => routeGuard(to, from, next),
    component: () =>
      import(
        /* webpackChunkName: "thank-you" */ "@/views/UpdateInvestmentQuestionnaire.vue"
        )
  },
  {
    path: "/:lang/questionnaire-evaluation/:token",
    name: "UpdateQuestionnaireEvaluation",
    beforeEnter: (to, from, next) => routeGuard(to, from, next),
    component: () =>
      import(
        /* webpackChunkName: "questionnaire-evaluation" */ "@/views/UpdateQuestionnaireEvaluation.vue"
      )
  },
  {
    path: "/:lang/update-thank-you",
    name: "UpdateThankYou",
    beforeEnter: (to, from, next) => routeGuard(to, from, next),
    component: () =>
      import(
        /* webpackChunkName: "questionnaire-evaluation" */ "@/views/UpdateThankYou.vue"
        )
  },
  {
    path: "/:lang/questionnaire-info/:token",
    name: "UpdateQuestionnaireInfo",
    beforeEnter: (to, from, next) => routeGuard(to, from, next),
    component: () =>
      import(
        /* webpackChunkName: "questionnaire-info" */ "@/views/UpdateQuestionnaireInfo.vue"
      )
  },
  {
    path: "/forgot-password",
    name: "public-forgot-password",
    component: () => import("@/components/ForgotPassword.vue"),
    meta: {
      title: "public-forgot-password"
    }
  },
  {
    path: "/:lang/reset-password/:token",
    name: "public-reset-password",
    component: () => import("@/components/ResetPassword.vue"),
    meta: {
      title: "public-reset-password"
    }
  },
  {
    path: "/:lang/new_strategy/:token",
    // meta: { step: 8 },
    name: "AddNewInvestmentStrategy",
    beforeEnter: (to, from, next) => routeGuard(to, from, next),
    component: () =>
      import(
        /* webpackChunkName: "thank-you" */ "@/views/AddNewInvestmentStrategy.vue"
        )
  },
  {
    path: "/:catchAll(.*)",
    name: "NotFound",
    redirect: "/",
    meta: {
      requiresAuth: false
    }
  }
];


const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes
});

// router.beforeEach((to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
//   const currentStep: any = from.meta.step;
//   const nextStep: any = to.meta.step;
//
//   if (currentStep !== undefined && nextStep !== undefined) {
//     // Only allow going forward to the next step
//     if (nextStep >= currentStep) {
//       next();
//     } else {
//       next(false);
//     }
//   } else {
//     next();  // Allow navigation for routes without step metadata
//   }
// });

export default router;
