import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-d948666c")
const _hoisted_1 = { class: "fc p-12 bg-primary-100 ml-2 mr-2 flex flex-col justify-between" }
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = ["innerHTML"]
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h4", {
      class: "text-base font-bold",
      innerHTML: _ctx.question
    }, null, 8, _hoisted_2),
    _createElementVNode("p", {
      class: "font-serif mt-5 ml-1",
      innerHTML: _ctx.answer
    }, null, 8, _hoisted_3)
  ]))
}