<template>
  <div
    v-click-outside="closeTooltip"
    @click="toggleTooltip()"
    class="
      btn
      secondary
      inverted
      cursor-pointer
      flex
      items-center
      px-3.5
      py-1
      border border-white
      text-white
      font-light
      fixed
      z-20
      right-9
      bottom-9
    "
  >
    <span class="w-full text-center text-3xl pt-1 px-1 pointer-events-none">
      ?
    </span>
    <div
      v-if="isTooltipOpen"
      class="
        flex flex-col
        space-y-5
        absolute
        bottom-14
        border border-white
        text-white
        normal-case
        text-left
        right-0
        -mr-1
        p-8
        pb-16
        w-72
        bg-primary-300
        text-sm
      "
    >
      <p>{{ $t("tooltip.title") }}</p>
      <p>{{ $t("tooltip.subtitle") }}</p>
      <p class="font-serif">{{ $t("tooltip.text1") }}</p>
      <p class="font-serif">{{ $t("tooltip.text2") }}</p>
    </div>
  </div>
</template>

<script lang="js">
import { Options, Vue } from "vue-class-component";

@Options({
  data() {
    return {
      isTooltipOpen: false
    }
  },
  computed: {
    isHomePage() {
      return this.$route.path === "/" ? true : false;
    },
  },
  methods: {
    toggleTooltip() {
      this.isTooltipOpen = !this.isTooltipOpen
    },
    closeTooltip() {
      this.isTooltipOpen = false
    },
  }
})
export default class HelpTooltip extends Vue {}
</script>
