// Custom Vue directive that detects click outside element
// Usage: v-click-outside="methodToExecute"

const clickOutside = {
  beforeMount: (element: any, binding: any): void => {
    element.clickOutsideEvent = (event: Event) => {
      if (!(element == event.target && element.contains(event.target))) {
        binding.value(); // Bind the method provided in attribute value
      }
    };
    document.addEventListener("click", element.clickOutsideEvent);
  },
  unmounted: (element: any): void => {
    document.removeEventListener("click", element.clickOutsideEvent);
  },
};

export default clickOutside;
