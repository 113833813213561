import { Store } from "vuex";
import { $t, handleApiResponse, showAlert } from "@/utils/helpers";

const alertTimer = 3000;

export interface Alert {
  key?: string;
  type?: string;
  title?: string;
  text: string;
}

export interface AlertState {
  alerts: Alert[];
}

const initialState: AlertState = {
  alerts: [],
};

export default {
  namespaced: true,
  state: initialState,
  getters: {
    allAlerts: (state: AlertState) => {
      return state.alerts;
    },
  },
  actions: {
    success: (store: any, alert: Alert): void => {
      store.dispatch("set", {
        type: "success",
        title: alert.title || "Success",
        text: alert.text,
      });
    },
    warning: (store: any, name: string) => {
      store.dispatch("set", {
        type: "warning",
        name: name,
      });
    },
    error: (store: any, alert: Alert): void => {
      store.dispatch("set", {
        type: "error",
        title: alert.title || "Error",
        text: alert.text || $t("error_occurred"),
      });
    },
    set: (store: any, alert: Alert): void => {
      const key = (
        new Date().valueOf() +
        (Math.random() * (0 - 9999999) + 9999999)
      ).toString();
      alert.key = key;
      store.commit("set", alert);
      setTimeout(() => {
        store.commit("delete", key);
      }, alertTimer);
    },
  },
  mutations: {
    set: (state: AlertState, alert: Alert): void => {
      state.alerts = [...state.alerts, alert];
    },
    delete(state: AlertState, key: string): void {
      state.alerts = state.alerts.filter((obj: Alert) => {
        return obj.key !== key;
      });
    },
  },
};
