import api from "@/store/api";
import { $t, handleApiResponse, showAlert } from "@/utils/helpers";
import router from "@/router";
import { getAuthToken, getLeadToken, removeLeadToken, setAuthToken, setLeadToken } from "@/utils/authentication";
import axios, { AxiosResponse } from "axios";
import Country from "@/shared/types/Country";
import { InvestmentStrategies, SelectedStrategy } from "@/shared/types/InvestmentStrategy";
import Contract from "@/shared/types/Contract";
import store from "@/store";

export interface OnboardingState {
  countries: Country[];
  profile: any;
  selectedStrategies: SelectedStrategy[];
  investmentStrategies: InvestmentStrategies | null;
  contracts: Contract[];
  sfaDocuments: any;
  childOwner: any;
  child: any;
  birthDate: any;
  isLoading: boolean;
  verified: any;
  token: string;
  signToken: string;
  lastQuestionaire: any;
  pep: any;
  verificationStatus: string;
  riskProfileName: string;
}

const initialState: OnboardingState = {
  countries: [],
  profile: null,
  selectedStrategies: [],
  investmentStrategies: null,
  sfaDocuments: [],
  contracts: [],
  childOwner: null,
  child: null,
  birthDate: null,
  isLoading: false,
  verified: false,
  token: "",
  signToken: "",
  lastQuestionaire: [],
  pep: "",
  verificationStatus: "",
  riskProfileName: ""
};

export default {
  namespaced: true,
  state: initialState,
  getters: {
    getToken: (state: OnboardingState): any => {
      return state.token;
    },
    getSignToken: (state: OnboardingState): any => {
      return state.signToken;
    },
    allCountries: (state: OnboardingState): Country[] => {
      return state.countries;
    },
    investmentStrategies: (
      state: OnboardingState
    ): InvestmentStrategies | null => {
      return state.investmentStrategies;
    },
    selectedStrategies: (state: OnboardingState): SelectedStrategy[] => {
      return state.selectedStrategies;
    },
    myProfile: (state: OnboardingState): any => {
      return state.profile;
    },
    allContracts: (state: OnboardingState): Contract[] => {
      return state.contracts;
    },
    sfaDocuments: (state: OnboardingState): any => {
      return state.sfaDocuments;
    },
    birthDate: (state: OnboardingState): any => {
      return state.birthDate;
    },
    verified: (state: OnboardingState): any => {
      return state.verified;
    },
    isLoading: (state: OnboardingState): boolean => {
      return state.isLoading;
    },
    childOwner: (state: OnboardingState): boolean => {
      return state.childOwner;
    },
    child: (state: OnboardingState): boolean => {
      return state.child;
    },
    lastQuestionaire: (state: OnboardingState): any => {
      return state.lastQuestionaire;
    }
  },
  actions: {
    async getCountries(store: any): Promise<void> {
      const response: AxiosResponse = await api.get(
        `enumerator/countries?lang=${localStorage.getItem("wem_locale")}`,
        getAuthToken()
      );
      store.commit("setCountries", response.data);
    },

    async getStrategies(store: any): Promise<void> {
      const response: AxiosResponse = await api.get(
        `onboarding/strategies?lang=${localStorage.getItem("wem_locale")}`,
        getLeadToken()
      );
      store.commit("setInvestmentStrategies", response.data);
    },

    async getLastQuestionaire(store: any): Promise<void> {
      const token = window.location.hash.split("/")[3];
      console.log("token", token);
      const response: AxiosResponse = await api.get(
        `client_zone/investment_questionnaire/last_questionnaire`,
        token
      );
      console.log("Response data: ", response.data);
      store.commit("setLastQuestionaire", response.data);
    },

    async getContracts(store: any): Promise<void> {
      const response: AxiosResponse = await api.get(
        `onboarding/documents?lang=${localStorage.getItem("wem_locale")}`,
        getLeadToken()
      );
      store.commit("setContracts", response.data);
    },

    async getSfaDocuments(store: any): Promise<void> {
      const response: AxiosResponse = await api.get(
        `onboarding/documents_intermediary?lang=${localStorage.getItem(
          "wem_locale"
        )}`,
        getLeadToken()
      );
      store.commit("setSfaDocuments", response.data);
    },

    async getSignCode(store: any, params: any): Promise<void> {
      const config = {
        headers: {
          Authorization: `Bearer ${getLeadToken()}`
        }
      };
      await axios
        .post(
          `${
            process.env.VUE_APP_API_LINK
          }onboarding/send-sign-code?lang=${localStorage.getItem(
            "wem_locale"
          )}`,
          {},
          config
        )
        .then(() => {
          store.dispatch(
            `alerts/success`,
            {
              title: "",
              text: $t("sign_code")
            },
            { root: true }
          );
        });
    },

    async getSignCodeSfa(store: any, params: any): Promise<void> {
      const config = {
        headers: {
          Authorization: `Bearer ${getLeadToken()}`
        }
      };
      await axios
        .post(
          `${
            process.env.VUE_APP_API_LINK
          }onboarding/send-sign-code-sfa?lang=${localStorage.getItem(
            "wem_locale"
          )}`,
          {},
          config
        )
        .then(() => {
          store.dispatch(
            `alerts/success`,
            {
              title: "",
              text: $t("sign_code")
            },
            { root: true }
          );
        });
    },

    async setAuthToken(store: any, { params }: { params: any }) {
      api
        .post("authentication", params, "")
        .then(function(response) {
          setAuthToken(response.data.authorization_token);
        })
        .catch((error) => {
          store.dispatch("alerts/warning", error, { root: true });
        });
    },

    async sendEmailCode(
      store: any,
      { params }: { params: any }
    ): Promise<void> {
      api
        .post(
          `onboarding/send-email-code?lang=${localStorage.getItem(
            "wem_locale"
          )}`,
          params,
          getAuthToken()
        )
        .then(function(response) {
          const successTitle = $t("email_code_title");
          const successMessage = $t("email_code");
          store.dispatch(
            `alerts/success`,
            {
              title: successTitle,
              text: successMessage
            },
            { root: true }
          );
          setLeadToken(response.data.lead_token);
          store.commit("setProfile", response.data);
        })
        .catch((response) => {
          console.log(response);
          store.dispatch(
            `alerts/error`,
            {
              title: $t("email_code_not_send_title"),
              text: $t("email_verification_error")
            },
            { root: true }
          );
        });
    },

    async sendSmsCode(store: any, { params }: { params: any }): Promise<void> {
      api
        .post(
          `onboarding/send-sms-code?lang=${localStorage.getItem("wem_locale")}`,
          params,
          getLeadToken()
        )
        .then(function(response) {
          store.dispatch(
            `alerts/success`,
            {
              title: $t("email_code_title"),
              text: $t("sms_code")
            },
            { root: true }
          );
          return response.data;
        })
        .catch((error) => {
          store.dispatch(
            `alerts/error`,
            {
              title: $t("email_code_not_send_title"),
              text: ""
            },
            { root: true }
          ),
            store.dispatch("alerts/warning", error, { root: true });
        });
    },

    async forgotPassword(
      store: any,
      { params }: { params: any }
    ): Promise<void> {
      axios
        .post(
          `${
            process.env.VUE_APP_API_LINK
          }forgot_password_onboarding?lang=${localStorage.getItem(
            "wem_locale"
          )}`,
          params
        )
        .then(function(response) {
          store.dispatch(
            `alerts/success`,
            {
              title: $t("email_code_send_title"),
              text: ""
            },
            { root: true }
          );
          return response.data;
        })
        .catch((error) => {
          console.log(error.response.status);
          // function limitMessage() {
          //   if (i18n.locale === 'sk') {
          //     return "Prekročili ste počet pokusov pre obnovu hesla. Skúste prosím opäť o 5 minút."
          //   } else if (i18n.locale === 'en') {
          //     return "You have exceeded the number of attempts to reset your password. Please try again in 5 minutes."
          //   } else {
          //     return "Превишили сте броя опити за нулиране на паролата. Моля, опитайте отново след 5 минути."
          //   }
          // }
          //
          if (error.response.status == 429) {
            store.dispatch(
              `alerts/error`,
              {
                title: $t("email_code_not_send_title"),
                text: $t("email_429")
              },
              { root: true }
            ),
              store.dispatch("alerts/warning", $t("email_429"), { root: true });
          } else {
            store.dispatch(
              `alerts/error`,
              {
                title: $t("email_code_not_send_title"),
                text: ""
              },
              { root: true }
            ),
              store.dispatch("alerts/warning", error, { root: true });
          }

          // store.dispatch(
          //   `alerts/error`,
          //   {
          //     title: $t("email_code_not_send_title"),
          //     text: ""
          //   },
          //   { root: true }
          // ),
          //   store.dispatch("alerts/warning", error, { root: true });
        });
    },

    async changeForgotPassword(
      store: any,
      { params }: { params: any }
    ): Promise<void> {
      axios
        .post(
          `${process.env.VUE_APP_API_LINK}change_lost_password_onboarding`,
          params
        )
        .then(function(response) {
          store.dispatch(
            `alerts/success`,
            {
              title: $t("password_change_success"),
              text: ""
            },
            { root: true }
          );
          return response.data;
        })
        .catch((error) => {
          store.dispatch(
            `alerts/error`,
            {
              title: "",
              text: $t("password_change_error")
            },
            { root: true }
          ),
            store.dispatch("alerts/warning", error, { root: true });
        });
    },

    async sendFormSignCode(store: any, params: any): Promise<void> {
      const token = store.getters["getToken"];
      // console.log(token);
      api
        .post(
          `client_zone/investment_questionnaire/request_sign_code`,
          params,
          token
        )
        .then(function(response) {
          store.dispatch(
            `alerts/success`,
            {
              title: $t("email_code_title"),
              text: $t("form.sms_toast")
            },
            { root: true }
          );
          return response.data;
        })
        .catch((error) => {
          store.dispatch(
            `alerts/error`,
            {
              title: $t("email_code_not_send_title"),
              text: ""
            },
            { root: true }
          ),
            store.dispatch("alerts/warning", error, { root: true });
        });
    },

    async sendEmailSignCode(store: any, params: any): Promise<void> {
      const token = store.getters["getToken"];
      // console.log(token);
      api
        .post(
          `client_zone/investment_questionnaire/request_sign_code_email`,
          params,
          token
        )
        .then(function(response) {
          store.dispatch(
            `alerts/success`,
            {
              title: $t("email_code_title"),
              text: $t("form.mail_toast")
            },
            { root: true }
          );
          return response.data;
        })
        .catch((error) => {
          store.dispatch(
            `alerts/error`,
            {
              title: $t("email_code_not_send_title"),
              text: ""
            },
            { root: true }
          ),
            store.dispatch("alerts/warning", error, { root: true });
        });
    },

    async signForm(store: any, params: any): Promise<void> {
      const token = store.getters["getToken"];
      // console.log(token);
      api
        .post(`client_zone/investment_questionnaire/sign`, params, token)
        .then(function(response) {
          if (response.data.result === "success") {
            store.dispatch(
              `alerts/success`,
              {
                title: $t("form_signed"),
                text: ""
              },
              { root: true }
            );
            router.push({
              name: "UpdateThankYou", // Name of your route
              params: { lang: localStorage.getItem("wem_locale") } // Pass language parameter if needed
            });
          } else {
            store.dispatch(
              `alerts/error`,
              {
                title: $t("form_signed_error"),
                text: ""
              },
              { root: true }
            );
          }
          return response.data;
        })
        .catch((error) => {
          if (error.response.status == 429) {
            store.dispatch(
              `alerts/error`,
              {
                title: $t("email_code_not_send_title"),
                text: $t("login_429")
              },
              { root: true }
            ),
              store.dispatch("alerts/warning", $t("login_429"), { root: true });
          } else {
            store.dispatch(
              `alerts/error`,
              {
                title: $t("form_signed_error"),
                text: ""
              },
              { root: true }
            ),
              store.dispatch("alerts/warning", error, { root: true });
          }
          // store.dispatch(
          //   `alerts/error`,
          //   {
          //     title: $t("form_signed_error"),
          //     text: ""
          //   },
          //   { root: true }
          // ),
          //   store.dispatch("alerts/warning", error, { root: true });
        });
    },

    async makeRegistration(store: any, { params }: { params: any }) {
      api
        .post("onboarding/verify-email-code", params, getLeadToken())
        .then(function(response) {
          if (response.data.result === "true") {
            api
              .post("onboarding/registration", params, getLeadToken())
              .then(function(response) {
                store.dispatch(
                  `alerts/success`,
                  {
                    title: "",
                    text: $t("registration_success")
                  },
                  { root: true }
                ),
                  router.push("/steps/personal-info");
              })
              .catch((error) => {
                store.dispatch("alerts/warning", error, { root: true });
              });
          } else {
            store.dispatch(
              `alerts/error`,
              {
                title: "",
                text: $t("sms_code_error")
              },
              { root: true }
            );
          }
        })
        .catch((error) => {
          store.dispatch("alerts/warning", error, { root: true });
        });
    },

    async makeLogin(store: any, { params }: { params: any }) {
      localStorage.removeItem("hasChild");
      removeLeadToken();
      api
        .post("onboarding/login", params, getAuthToken())
        .then(function(response) {
          setLeadToken(response.data.lead_token);
          store.commit("setProfile", response.data);

          const tokenUuid = JSON.parse(
            atob(response.data.lead_token.split(".")[1])
          );
          console.log(tokenUuid.sub);

          // const routeStep = response.data.refferer_url + 1;
          const routeStep = router.getRoutes().filter((a) => {
            return a.meta.step == parseInt(response.data.referrer_url) + 1; // mam ulozene ze odoslal krok 2 takze nech ide na krok 3
          });
          // console.log("Router LEn", routeStep.length);
          if (response.data.model == "Clients") {
            router.push({ name: "QuestionnaireInfo" });
          } else if (
            response.data.referrer_url === 9 &&
            response.data.verification_status === "submitted"
          ) {
            console.log("VerificationResult", tokenUuid.sub);
            // router.push({ name: "VerificationResult", params: { uuid: tokenUuid } });
            router.push(`/steps/verification-result/${tokenUuid.sub}`);
          } else if (
            response.data.referrer_url === 9 &&
            response.data.verification_status === "declined"
          ) {
            console.log("VerificationResult", tokenUuid.sub);
            // router.push({ name: "VerificationResult", params: { uuid: tokenUuid } });
            router.push(`/steps/verification-result/${tokenUuid.sub}`);
          } else if (routeStep.length > 0) {
            router.push({ name: routeStep[0].name });
          } else {
            router.push({ name: "Registration" });
          }
        })
        .catch(function(error) {
          // if (error.response.status == 429) {
          //
          //   store.dispatch(
          //     `alerts/error`,
          //     {
          //       title: $t("email_code_not_send_title"),
          //       text: $t("login_429")
          //     },
          //     { root: true }
          //   ),
          //     store.dispatch("alerts/warning", $t("login_429"), { root: true });
          // } else {
          //   store.dispatch(
          //     `alerts/error`,
          //     {
          //       title: "",
          //       text: $t("wrong_password")
          //     },
          //     { root: true }
          //   );
          // }
          store.dispatch(
            `alerts/error`,
            {
              title: $t("error_occurred"),
              text: $t("validations.wrong_password")
            },
            { root: true }
          );
        });
    },

    async sendPersonalInfo(store: any, data: any) {
      api
        .post("onboarding/verify-sms-code", data, getLeadToken())
        .then(function(response) {
          // store.commit("setVerified", response.data.result);
          if (response.data.result === "true") {
            api
              .post("onboarding/personal-info", data, getLeadToken())
              .then(function(res) {
                store.commit("setProfile", res.data);
                console.log(res);
                if (store.state.child === true) {
                  // console.log("child");
                  store.commit("setChildOwner", data);
                  router.push("/steps/child-personal-info");
                } else {
                  // console.log("notChild");
                  store.dispatch(
                    `alerts/success`,
                    {
                      title: "",
                      text: $t("personal_info_success")
                    },
                    { root: true }
                  ),
                    console.log("Push to", "/steps/address-and-bank-account");
                  router.push("/steps/address-and-bank-account");
                }
              })
              .catch(function(error) {
                // console.log(error);
                if (error.response.status === 409) {
                  store.dispatch(
                    `alerts/error`,
                    {
                      title: "",
                      text: $t("form.birthnumber_exist")
                    },
                    { root: true }
                  );
                } else {
                  store.dispatch(
                    `alerts/error`,
                    {
                      title: "",
                      text: ""
                    },
                    { root: true }
                  );
                }
              });
          } else {
            store.dispatch(
              `alerts/error`,
              {
                title: "",
                text: $t("sms_code_error")
              },
              { root: true }
            );
          }
        })
        .catch(() => {
          store.dispatch(
            `alerts/error`,
            {
              title: "",
              text: $t("sms_code_error")
            },
            { root: true }
          );
        });
    },

    async sendChildAndOwnerInfo(store: any, data: any) {
      api
        .post("onboarding/personal-child-info", data, getLeadToken())
        .then(function(response) {
          store.commit("setProfile", response.data);
          store.dispatch(
            `alerts/success`,
            {
              title: "",
              text: $t("personal_child_success")
            },
            { root: true }
          ),
            router.push("/steps/address-and-bank-account");
        })
        .catch((error) => {
          store.dispatch("alerts/warning", error, { root: true });
        });
    },

    // async sendAddressAndBankAccountInfo(store: any, data: any) {
    //   api
    //     .post("onboarding/address-and-bank-account", data, getLeadToken())
    //     .then(function(response) {
    //       store.commit("setProfile", response.data);
    //       store.dispatch(
    //         `alerts/success`,
    //         {
    //           title: "",
    //           text: $t("address_success")
    //         },
    //         { root: true }
    //       ),
    //         router.push("/steps/questionnaire-info");
    //     })
    //     .catch((error) => {
    //       store.dispatch("alerts/warning", error, { root: true });
    //     });
    // },

    async sendAddressAndBankAccountInfo(
      store: any,
      formData: any
    ): Promise<void> {
      store.commit("setLoadingState", true);
      axios
        .post(
          `${process.env.VUE_APP_API_LINK}onboarding/address-and-bank-account`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${getLeadToken()}`
            }
          }
        )
        .then((response) => {
          store.dispatch(
            `alerts/success`,
            {
              title: "",
              text: $t("address_success")
            },
            { root: true }
          ),
            store.commit("setLoadingState", false);
          console.log("Bank response", response.data);
          localStorage.setItem("hasChild", response?.data?.client_owner);
          router.push("/steps/questionnaire-info");
        })
        .catch((error) => {
          store.commit("setLoadingState", false);
          console.error(error);
        });
    },

    async sendDocumentsUpload(store: any, formData: any): Promise<void> {
      store.commit("setLoadingState", true);
      axios
        .post(
          `${process.env.VUE_APP_API_LINK}onboarding/upload-documents`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${getLeadToken()}`
            }
          }
        )
        .then((response) => {
          store.dispatch(
            `alerts/success`,
            {
              title: "",
              text: $t("documents_success")
            },
            { root: true }
          ),
            store.commit("setLoadingState", false);
          localStorage.removeItem("hasChild");
          router.push("/steps/contracts");
        })
        .catch((error) => {
          store.commit("setLoadingState", false);
          console.error(error);
        });
    },

    async startVerification(store: any, data: any) {
      api
        .post("onboarding/start-verification", data, getLeadToken())
        .then(function(response) {
          console.log(response.data.url);
          window.location.href = response.data.url;
        })
        .catch((error) => {
          store.dispatch("alerts/warning", error, { root: true });
        });
    },

    async verificationResult(store: any) {
      try {
        const response = await api.get(
          "onboarding/get-verification-status",
          getLeadToken()
        );
        console.log(response.data);
        store.commit("setVerificationStatus", response.data?.verification_status);
        store.commit("setProfile", response.data);
        return response.data?.verification_status;
      } catch (error) {
        store.dispatch("alerts/warning", error, { root: true });
        throw error; // re-throw the error if you want the caller to handle it
      }
    },

    async verifySignCodeSfa(store: any, params: URLSearchParams): Promise<AxiosResponse> {
      try {
        const response = await axios.post(
          `${process.env.VUE_APP_API_LINK}onboarding/verify-sign-code-sfa`,
          params,
          {
            headers: {
              Authorization: `Bearer ${getLeadToken()}`
            }
          }
        );
        if (response.data.result === "true") {
          store.dispatch(
            `alerts/success`,
            {
              title: "",
              text: $t("contract.sign_sfa_success")
            },
            { root: true }
          );
        } else {
          store.dispatch(
            `alerts/error`,
            {
              title: "",
              text: $t("contract.sign_sfa_failed_code")
            },
            { root: true }
          );
        }
        return response; // Return the response to check the status in the component
      } catch (error) {
        console.error(error);
        store.dispatch(
          `alerts/error`,
          {
            title: "",
            text: $t("contract.sign_sfa_failed")
          },
          { root: true }
        );
        throw error; // Propagate the error to the component
      }
    },

    async verifySignCode(store: any, params: URLSearchParams): Promise<AxiosResponse> {
      try {
        const response = await axios.post(
          `${process.env.VUE_APP_API_LINK}onboarding/verify-sign-code`,
          params,
          {
            headers: {
              Authorization: `Bearer ${getLeadToken()}`
            }
          }
        );
        if (response.data.result === "true") {
          store.dispatch(
            `alerts/success`,
            {
              title: "",
              text: $t("contract.sign_ocp_success")
            },
            { root: true }
          );
        } else {
          store.dispatch(
            `alerts/error`,
            {
              title: "",
              text: $t("contract.sign_ocp_failed_code")
            },
            { root: true }
          );
        }
        return response; // Return the response to check the status in the component
      } catch (error) {
        console.error(error);
        store.dispatch(
          `alerts/error`,
          {
            title: "",
            text: $t("contract.sign_ocp_failed")
          },
          { root: true }
        );
        throw error; // Propagate the error to the component
      }
    }


  },

  mutations: {
    checkProfile(state: any) {
      if (
        !state.profile &&
        router.currentRoute.value.name !== "VerificationResult"
      ) {
        router.push({ name: "Login" });
      }
    },
    setToken(state: OnboardingState, token: string): void {
      state.token = token;
    },
    setSignToken(state: OnboardingState, token: string): void {
      state.signToken = token;
    },
    setVerificationStatus(state: OnboardingState, status: string): void {
      state.verificationStatus = status;
    },
    setLoadingState: (state: OnboardingState, value: boolean): void => {
      state.isLoading = value;
    },
    setCountries: (state: OnboardingState, countries: Country[]): void => {
      state.countries = countries;
    },
    setProfile: (state: OnboardingState, profile: any): void => {
      state.profile = profile;
    },
    setInvestmentStrategies: (
      state: OnboardingState,
      strategies: InvestmentStrategies
    ): void => {
      state.investmentStrategies = strategies;
    },
    setContracts: (state: OnboardingState, contracts: Contract[]): void => {
      state.contracts = contracts;
    },
    setSfaDocuments: (state: OnboardingState, sfaDocuments: any): void => {
      state.sfaDocuments = sfaDocuments;
    },
    setPep: (state: OnboardingState, pep: any): void => {
      state.pep = pep;
    },
    setLastQuestionaire: (
      state: OnboardingState,
      lastQuestionaire: any
    ): void => {
      state.lastQuestionaire = lastQuestionaire;
    },
    setBirthDate: (state: OnboardingState, birthDate: any): void => {
      state.birthDate = birthDate;
    },
    setVerified: (state: OnboardingState, verified: any): void => {
      state.verified = verified;
    },
    setChildOwner: (state: OnboardingState, childOwner: any): void => {
      state.childOwner = childOwner;
    },
    setChild: (state: OnboardingState, child: any): void => {
      console.log("Settting child", child);
      state.child = child;
    },
    setRiskProfileName: (
      state: OnboardingState,
      riskProfileName: string
    ): void => {
      state.riskProfileName = riskProfileName;
    },
    selectInvestmentStrategy: (
      state: OnboardingState,
      strategy: SelectedStrategy
    ): void => {
      const added = state.selectedStrategies.find(
        (s) => s.investment_strategy_id === strategy.investment_strategy_id
      );

      if (added) {
        state.selectedStrategies = state.selectedStrategies.filter(
          (s) => s.investment_strategy_id !== strategy.investment_strategy_id
        );
      } else {
        state.selectedStrategies = [...state.selectedStrategies, strategy];
      }
    },
    updateSelectedStrategy: (
      state: OnboardingState,
      strategy: SelectedStrategy
    ): void => {
      const existingStrategy: any = state.selectedStrategies.find(
        (s) => s.investment_strategy_id === strategy.investment_strategy_id
      );

      const index = state.selectedStrategies.indexOf(existingStrategy);

      state.selectedStrategies[index] = strategy;
    },
    async saveSelectedStrategies(state: OnboardingState): Promise<void> {
      const strategie = JSON.stringify(state.selectedStrategies);
      // console.log("strategie", strategie);
      if (strategie !== "[]") {
        const params = new URLSearchParams();
        params.append("strategies", strategie);
        handleApiResponse(
          "onboarding/save-strategies",
          store,
          params,
          getLeadToken(),
          {
            successMessage: $t("strategies_success"),
            successCallback: (response: AxiosResponse) => {
              router.push("/steps/start-verification");
            }
          }
        );
      } else {
        showAlert(
          "error",
          $t("investment_strategy.invalid_strategies_error"),
          ""
        );
      }
    },

    async verifySignCode(params: any): Promise<AxiosResponse> {
      return axios.post(
        `${process.env.VUE_APP_API_LINK}onboarding/verify-sign-code`,
        params,
        {
          headers: {
            Authorization: `Bearer ${getLeadToken()}`
          }
        }
      );
    },

    async verifySignCodeSfa(params: any): Promise<AxiosResponse> {
      return axios.post(
        `${process.env.VUE_APP_API_LINK}onboarding/verify-sign-code-sfa`,
        params,
        {
          headers: {
            Authorization: `Bearer ${getLeadToken()}`
          }
        }
      );
    },

    async saveContracts(state: OnboardingState): Promise<void> {
      state.isLoading = true;
      try {
        handleApiResponse(
          `onboarding/contract?lang=${localStorage.getItem("wem_locale")}`,
          store,
          {},
          getLeadToken(),
          {
            successMessage: $t("documents_sent"),
            successCallback: (response: AxiosResponse) => {
              router.push("/steps/thank-you");
              state.isLoading = false;
            },
            errorCallback: () => {
              state.isLoading = false;
              store.dispatch(
                `alerts/error`,
                {
                  title: "",
                  text: $t("error_occurred")
                },
                { root: true }
              );
            }
          }
        );
        state.isLoading = false;
      } catch (error) {
        state.isLoading = false;
        store.dispatch(
          `alerts/error`,
          {
            title: "",
            text: $t("sign_error")
          },
          { root: true }
        );
        console.error(error);
      }
    }

  }
};
