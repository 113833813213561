import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: " h-full relative"
}
const _hoisted_2 = {
  key: 1,
  class: "flex-grow w-full md:p-10 p-6 pb-0"
}
const _hoisted_3 = { class: "max-w-5xl mx-auto h-full relative" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Header = _resolveComponent("Header")!
  const _component_MaintenancePage = _resolveComponent("MaintenancePage")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_HelpTooltip = _resolveComponent("HelpTooltip")!
  const _component_Footer = _resolveComponent("Footer")!
  const _component_Alert = _resolveComponent("Alert")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Header),
    (_ctx.maintenanceMode)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_MaintenancePage)
        ]))
      : (_openBlock(), _createElementBlock("main", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_router_view)
          ]),
          _createVNode(_component_HelpTooltip)
        ])),
    _createVNode(_component_Footer),
    _createVNode(_component_Alert)
  ], 64))
}