import router from "@/router";
import { getAuthToken, getLeadToken } from "@/utils/authentication";
import axios, { AxiosResponse } from "axios/index";
import { getLocale } from "@/i18n";

export const apiLink = (): string => {
  return process.env.VUE_APP_API_LINK || '';
};

export const apiURL = (): string => {
  return process.env.VUE_APP_BASE_URL || '';
};

export default {
  async get(link: string, token: any) {
    return await axios.get(apiLink() + link, {
      headers: { Authorization: `Bearer ${token}` },
    });
  },

  async post(link: string, data: any, token: any) {
    return await axios.post(apiLink() + link, data, {
      headers: { Authorization: `Bearer ${token}` },
    });
  },
};
