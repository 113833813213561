import api from "@/store/api";
import { $t, handleApiResponse } from "@/utils/helpers";
import router from "@/router";
import {
  Questionnaire,
  QuestionnaireSection,
  SelectedAnswer,
} from "@/shared/types/Questionnaire";
import { Questionnaires } from "../questionnaires/questionnaires";
import { AxiosResponse } from "axios";
import { getAuthToken, getLeadToken } from "@/utils/authentication";
import store from "@/store";
import { getLocale } from "@/i18n";

interface QuestionnaireState {
  currentSection: number;
  langugage: string;
  sections: QuestionnaireSection[];
  selectedAnswers: SelectedAnswer[];
  riskProfiles: [];
  riskProfile: number;
  investorName: string;
  token: string;
  signToken: string;
}

const initialState: QuestionnaireState = {
  currentSection: 1,
  langugage: "sk",
  sections: [],
  selectedAnswers: [],
  riskProfiles: [],
  riskProfile: 0,
  investorName: "",
  token: "",
  signToken: "",
};

export default {
  namespaced: true,
  state: initialState,
  getters: {
    getQuestionnaire: (state: QuestionnaireState): Questionnaire => {
      return {
        language: state.langugage,
        sections: state.sections,
      };
    },
    getCurrentSection: (state: QuestionnaireState): number => {
      return state.currentSection;
    },
    getRiskProfile: (state: QuestionnaireState): number => {
      return state.riskProfile;
    },
    getRiskProfiles: (state: QuestionnaireState): any => {
      return state.riskProfiles;
    },
    getInvestorName: (state: QuestionnaireState): any => {
      return state.investorName;
    },
    getToken: (state: QuestionnaireState): any => {
      return state.token;
    },
    getSignToken: (state: QuestionnaireState): any => {
      return state.signToken;
    },
  },
  actions: {
    nextSection: (store: any, answers: SelectedAnswer[]): void => {
      store.commit("updateSelectedAnswers", answers);
    },
    updateNextSection: (store: any, answers: SelectedAnswer[]): void => {
      store.commit("updateUpdateSelectedAnswers", answers);
    },
    setQuestionnaire: (store: any, language: string): void => {
      store.commit("setQuestionnaire", language);
    },
    setRiskProfile: (store: any, riskProfile: number): void => {
      store.commit("setRiskProfile", riskProfile);
    },
    async getRiskProfiles(store: any): Promise<void> {
      const response: AxiosResponse = await api.get(
        "enumerator/risk_profiles",
        getAuthToken()
      );
      console.log(response.data);

      store.commit("setRiskProfiles", response.data);
      localStorage.setItem("riskProfiles", JSON.stringify(response.data));
      // console.log("Commiting risk profiles", response.data);
      // console.log("State risk profiles", store.state.riskProfiles);
    },
  },
  mutations: {
    setToken(state: QuestionnaireState, token: string): void {
      state.token = token;
    },
    setSignToken(state: QuestionnaireState, token: string): void {
      state.signToken = token;
    },
    setQuestionnaire: (state: QuestionnaireState, language: string): void => {
      language = "sk";
      const questionnaire = Questionnaires.find((q) => q.language === language);
      state.langugage = language;
      state.sections = questionnaire ? questionnaire.sections : [];
    },
    setRiskProfile: (state: QuestionnaireState, riskProfile: any): void => {
      state.riskProfile = riskProfile;
    },
    setRiskProfiles: (state: QuestionnaireState, riskProfiles: any): void => {
      state.riskProfiles = riskProfiles;
    },
    setInvestorName: (state: QuestionnaireState, investorName: any): void => {
      state.investorName = investorName;
    },
    updateSelectedAnswers: async (
      state: QuestionnaireState,
      answers: SelectedAnswer[]
    ): Promise<void> => {
      state.selectedAnswers = [...state.selectedAnswers, ...answers];
      if (state.currentSection < state.sections.length) {
        state.currentSection = state.currentSection + 1;
      } else {
        const odpovede = JSON.stringify(state.selectedAnswers);
        const params = new URLSearchParams();
        params.append("odpovede", odpovede);

        handleApiResponse(
          "onboarding/questionnaire",
          store,
          params,
          getLeadToken(),
          {
            successMessage: $t("questionnaire_success"),
            successCallback: (response: AxiosResponse) => {
              store.commit("onboarding/setProfile", response.data);
              state.riskProfile = response.data.risk_profile_id;
              router.push("/steps/questionnaire-evaluation");
            },
          }
        );
      }
    },
    updateUpdateSelectedAnswers: async (
      state: QuestionnaireState,
      answers: SelectedAnswer[]
    ): Promise<void> => {
      state.selectedAnswers = [...state.selectedAnswers, ...answers];
      if (state.currentSection < state.sections.length) {
        state.currentSection = state.currentSection + 1;
      } else {
        const odpovede = JSON.stringify(state.selectedAnswers);
        const params = new URLSearchParams();
        params.append("odpovede", odpovede);
        params.append("lang", getLocale());

        handleApiResponse(
          "client_zone/investment_questionnaire/new",
          store,
          params,
          store.getters["questionnaire/getToken"],
          {
            successMessage: $t("questionnaire_success"),
            successCallback: (response: AxiosResponse) => {
              store.commit(
                "questionnaire/setSignToken",
                response.data.signature_token
              );
              store.commit(
                "onboarding/setSignToken",
                response.data.signature_token
              );
              // store.commit("questionnaire/setRiskProfiles", response.data.risk_profile_id);
              localStorage.setItem(
                "signatureToken",
                response.data.signature_token
              );
              state.riskProfile = response.data.risk_profile;
              router.push(`/sk/questionnaire-evaluation/${state.token}`);
            },
          }
        );
      }
    },
  },
};
