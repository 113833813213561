import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import i18n from "./i18n";
import VCalendar from "v-calendar";
import { BootstrapIconsPlugin } from "bootstrap-icons-vue";
import * as Sentry from "@sentry/vue";

import clickOutside from "./shared/directives/ClickOutside";
import "./main.scss";
import { RouteLocationNormalized } from "vue-router";

// router.beforeEach((to, from, next) => {
//   const desiredStep = from.meta.step; // Get the desired step from the route meta
//
//   // Check if the desired step matches the actual step in the route
//   if (desiredStep && desiredStep !== getCurrentStepFromRoute(to)) {
//     // Redirect the user to the correct step route
//     const stepRoute = findStepRouteByStep(desiredStep);
//     if (stepRoute) {
//       next({ name: stepRoute.name });
//     } else {
//       // Handle the case if the desired step doesn't have a defined route
//       next({ name: "NotFound" }); // Redirect to a Not Found page or any other suitable route
//     }
//   } else {
//     next(); // Proceed to the next navigation step
//   }
// });



// Function to extract the current step from the route
function getCurrentStepFromRoute(route: RouteLocationNormalized) {
  return route.meta.step; // Assuming the step is stored in the route meta
}

// Function to find the step route by the desired step
function findStepRouteByStep(step: unknown) {
  return router.getRoutes().find(route => route.meta?.step === step);
}

createApp(App)
  .use(i18n)
  .use(store)
  .use(BootstrapIconsPlugin)
  .use(VCalendar, {
    componentPrefix: "vc"
  })
  .use(router)
  .directive("click-outside", clickOutside)
  .mount("#app");

Sentry.init({
  Vue: createApp(App),
  dsn: process.env.VUE_APP_SENTRY_DSN,
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router)
    }),
    new Sentry.Replay()
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,

  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["*", "192.168.52.11", "192.168.52.11:9098"],
  // tracePropagationTargets: ["*", "10.52.52.6", "10.52.52.6:9092"],

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0
});
